import React, { Component } from "react";
import { Icon } from '@iconify/react';
import styles from './styles/notificationPopup.module.scss';
import { withTranslation } from 'react-i18next';

class ActionButton extends Component {
    render() {
        const { label, className, title, icon, iconClassName, onClick } = this.props;
        return (
            <button
                type="button"
                className={`${styles.actionButton} ${className}`}
                title={title}
                onClick={onClick}
            >
                <Icon icon={icon} className={iconClassName} />
                {label}
            </button>
        );
    }
}

class NotificationPopup extends Component {
    handleOverlayClick = (e) => {
        const { onClose } = this.props;
        if (onClose && e.target === e.currentTarget) {
            onClose();
        }
    };

    render() {
        const { title, body, buttons, onClose, t } = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.notificationPopup}>
                    <div className={styles.info}>
                        <Icon icon="mdi:bell" className={styles.infoIcon} />
                        <span className={styles.infoTitle}>{t("NOTIFICATIONS.LABEL.NewNotification")}</span>
                    </div>
                    {onClose && (
                        <button className={styles.closeButton} onClick={onClose}>
                            <Icon icon="mdi:close" />
                        </button>
                    )}

                    <h1 className={styles.popupTitle} dangerouslySetInnerHTML={{ __html: title }}></h1>

                    <div className={styles.popupBody}>
                        {body}
                    </div>
                    {buttons && buttons.length > 0 && (
                        <div className={styles.buttonGroup}>
                            {buttons.map((button, index) => (
                                <ActionButton
                                    key={index}
                                    label={button.label}
                                    className={button.className}
                                    title={button.title}
                                    icon={button.icon}
                                    iconClassName={button.iconClassName}
                                    onClick={button.onClick}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(NotificationPopup);
