import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import styles from './styles/stateCall.module.scss';
import { getStateCallTranslation } from '../../utils/translation.util.js'
import { getFormattedTimeFromLocalTime } from "../../utils/date.util.js";
import { StateCall } from "../../utils/StateCallEnum.js";
import classNames from 'classnames';

const getStateCallIcon = (state) => {
    switch (state) {
        case StateCall.ON_CALL:
            return (
                <Icon icon="line-md:phone-call-loop" className={styles.green} width="20" height="20" />
            );
        case StateCall.LOST:
            return (
                <Icon icon="material-symbols:phone-missed" className={styles.red} width="20" height="20" />
            );
        case StateCall.COMPLETED:
            return (
                <Icon icon="f7:phone-down-fill" className={styles.black} width="20" height="20" />
            );
        default:
            return null;
    }
};

const getStateCallClass = (state) => {
    switch (state) {
        case StateCall.LOST:
            return styles.red;
        case StateCall.COMPLETED:
            return styles.black;
        case StateCall.ON_CALL:
            return styles.green;
        default:
            return "";
    }
};

class StateCallComponent extends Component {
    render() {
        const { state, time, t } = this.props;
        return (
            <>
                {getStateCallIcon(state)}
                <div className={classNames(styles.callDetails, getStateCallClass(state))}>
                    <span className={styles.stateCall}>{getStateCallTranslation(t, state)}</span>
                    {time && (<>
                        <span> | </span>
                        <span className={styles.boldTime}>{getFormattedTimeFromLocalTime(time)}</span>
                    </>)}
                </div>
            </>

        );
    }
}

export default withTranslation()(StateCallComponent)