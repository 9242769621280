import { createSlice } from '@reduxjs/toolkit';
import { State } from '../../utils/StateEnum';

const initialState = {
  userRoles: null,
  email: null,
  id: null,
  userStatus: State.OFFLINE,
  avatar: null,
  homes: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRoles(state, action) {
      state.userRoles = action.payload.roles;
    },
    setUserInfo(state, action) {
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.avatar = action.payload.avatar;
    },
    clearUserRoles(state) {
      state.userRoles = null;
    },
    clearUserInfo(state) {
      state.email = null;
      state.id = null;
      state.avatar = null;
    },
    setUserStatus(state, action) {
      state.userStatus = action.payload.status;
    },
    clearUserStatus(state) {
      state.userStatus = State.OFFLINE;
    },
    setUserHomes(state, action) {
      state.homes = action.payload.homes;
    },
    clearUserHomes(state) {
      state.homes = [];
    },
  },
});

export const {
  setUserRoles,
  setUserInfo,
  clearUserRoles,
  clearUserInfo,
  setUserStatus,
  clearUserStatus,
  setUserHomes,
  clearUserHomes,
} = userSlice.actions;
export default userSlice.reducer;