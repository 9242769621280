import { store } from '../redux/store.js';
import { errorHandler } from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = localStorage.getItem('accessToken');

const HomeService = {
    getHomesByUser: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/homes/all-user', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    searchHomes: async (term, active) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let url = `${API_URL}/api/v1/homes/search?term=${encodeURIComponent(term)}`;
            if (active !== undefined) {
                url += `&active=${active}`;
            }
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });
    
            errorHandler(response.status);
            
            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    
    changeStatus: async (id) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/homes/change-status/'+id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    createHome: async (home) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/homes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(home)
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    updateHome: async (home) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/homes/'+ home.id.toString(), {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(home)
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    deleteHome: async (homeId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/homes/'+ homeId.toString(), {
                method: 'DELETE',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                return;
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },

    getUsersByHomes: async (homeIds) => {
        const tenantName = store.getState().tenant.tenant;
        if (homeIds === undefined || homeIds.length === 0) return [];
        try {
            const response = await fetch(API_URL + '/api/v1/homes/'+homeIds.toString()+'/users', {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            errorHandler(response.status);
            
            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    
}

export const { getHomesByUser, searchHomes, changeStatus, createHome, updateHome, deleteHome, getUsersByHomes } = HomeService;
