import React, { Component } from "react";
import Avatar from "../avatar/avatar.component";
import { withTranslation } from 'react-i18next';
import { getUserTypeTranslation } from '../../utils/translation.util.js';
import { Icon } from '@iconify/react';
import styles from './styles/contactDetail.module.scss';
import AccordionHistoryComponent from "../accordion-history/accordionHistory.component.jsx";
import { createCall } from '../../services/VideoCallService';
import { playRingtone } from '../../utils/videocall.util.js';
import { getImage } from '../../services/BlobService';
import Modal from "../modal/modal.component.jsx";

class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null,
            showUserNotAvaliableModal: false
        };
    }

    fetchImage = async (imageId) => {
        if (imageId) {
            try {
                const url = await getImage(imageId);
                this.setState({ imageSrc: url });
            } catch (error) {
                console.error('Error fetching image:', error);
                this.setState({ imageSrc: null });
            }
        } else {
            this.setState({ imageSrc: null });
        }
    };

    componentDidMount() {
        const { contact } = this.props;
        this.fetchImage(contact.avatar);
    }

    componentDidUpdate(prevProps) {
        if (this.props.contact !== prevProps.contact) {
            this.fetchImage(this.props.contact.avatar);
        }
    }
    handleCloseUserNotAvaliableModal = () => {
        this.setState({ showUserNotAvaliableModal: false });
    };


    handleCall = async (contact) => {
        /*   if (Object.keys(errors).length > 0) {
             this.setState({ errors });
             return;
           }*/
        if (contact.state !== 'ONLINE') {
            this.setState({ showUserNotAvaliableModal: true });
        } else {
            try {
                //TODO replace with real user

                var callResponse = await createCall(contact);
                if (callResponse) {
                    console.debug(callResponse);
                    /*  debugger;
                      //Set State de la respuesta como videoCall?? Global?
                      // this.props.handleActionClick(Action.USER_DETAIL, contact)
                     this.setState({ mainContent: <VideoCallJitsi appId={callResponse.appId} roomId={callResponse.roomId} token={callResponse.token} /> });*/
                }

                // mensaje en la UI
                //this.props.onClose(true);

                playRingtone(true);
            } catch (error) {
                console.error('Error inicializando llamada', error);
                // mensaje en la UI
                //this.props.onClose(false);
            }
        }
    };

    render() {
        const { contact, t, showHistory } = this.props;
        const { imageSrc, showUserNotAvaliableModal } = this.state;

        console.log("show  :  " + showHistory);
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <Avatar image={imageSrc} name={contact.name} status={contact.state} backgroundColor={contact.backgroundColor} size='avatar-very-hard' />
                    <h2 className={styles.role}>{getUserTypeTranslation(t, contact.userType)}</h2>
                    <h1 className={styles.name}>{contact.name} {contact.surname}</h1>
                    <span className={styles.email}>{contact.email}</span>
                    <button className={styles.videocallButton} onClick={() => this.handleCall(contact)} >
                        <Icon icon="fluent:video-24-filled" width="20" height="20" />
                        <span className={styles.videocallText}>{t("CONTACT_DETAIL.BUTTON.Videocall")}</span>
                    </button>
                    <div className={styles.miscSection}>
                        <div className={styles.niu}>{contact.homes[0].niu}</div>
                        <Icon icon="tabler:point-filled" className={styles.infoIcon} width="15" height="15" />
                        <div className={styles.homeName}>{contact.homes[0].name}</div>
                    </div>
                </div>
                {showHistory && <AccordionHistoryComponent contact={contact} />}
                {showUserNotAvaliableModal && (
                    <Modal
                        title={`${contact.name}  ${t("CONTACT_DETAIL.LABEL.userNotAllowTitle")}`}
                        titleClassName={styles.modalTitle}
                        children={t("CONTACT_DETAIL.LABEL.userNotAllowContent")}
                        avatar={ <Avatar image={imageSrc} name={contact.name}  backgroundColor={contact.backgroundColor} size='avatar-hard' />}
                        onClose={this.handleCloseUserNotAvaliableModal}
                        buttons={
                            [{ label: t("CONTACT_DETAIL.BUTTON.success"), className: styles.modalAcceptButton, onClick: this.handleCloseUserNotAvaliableModal }]
                        }
                    />)}

            </div>
        );
    }
}

export default withTranslation()(ContactDetail);
