import { store } from '../redux/store.js';
import { errorHandler } from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const UserTypeService = {
    getUserTypes: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            const response = await fetch(API_URL + '/api/v1/user-types', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);
            
            if(response.ok){
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching UserTypes:', error);
            throw error;
        }
    }
}

export const { getUserTypes } = UserTypeService;
