const Action = Object.freeze({
    CLEAN: 'CLEAN',
    USER_DETAIL: 'USER_DETAIL',    
    USER_DETAIL_HISTORY: 'USER_DETAIL_HISTORY',
    HOMES: 'HOMES',
    CONTENT: 'CONTENT',
    USERS: 'USERS',
    CONTACTS: 'CONTACTS',
    VIDEOCALL: 'VIDEOCALL',
    CALENDAR: 'CALENDAR',
    PROFILE: 'PROFILE',
});

export { Action };