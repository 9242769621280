import React, { Component } from "react";
import styles from './styles/loadingScreen.module.scss';

class LoadingScreen extends Component {
  render() {
    return (
      <div className={styles.loadingOverlay}>
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}></div>
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
