import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import Modal from '../../modal/modal.component';
import styles from './assets/styles/rejectedVideoCall.module.scss';
import {playRingtone} from "../../../utils/videocall.util";
import {getImage} from "../../../services/BlobService";
import Avatar from "../../avatar/avatar.component";

class RejectedVideoCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null
        };
    }

    fetchImage = async (imageId) => {
        if (imageId) {
            this.setState({isLoadingImage: true});
            try {
                const url = await getImage(imageId);
                this.setState({imageSrc: url, image: url, isLoadingImage: false});
            } catch (error) {
                console.error('Error fetching image:', error);
                this.setState({isLoadingImage: false});
            }
        } else {
            this.setState({isLoadingImage: false});
        }
    };

    componentDidMount() {
        const {rejectingUser} = this.props;
        playRingtone(true);
        this.fetchImage(rejectingUser.avatar);
    }

    handleOverlayClick = (e) => {
        const {onClose} = this.props;
        if (onClose && e.target === e.currentTarget) {
            onClose();
        }
    };

    render() {
        const {t, onClose, rejectingUser, children} = this.props;
        const buttons = [
            {label: t("VIDEOCALL.REJECTED.OkButton"), className: styles.modalAcceptButton, onClick: () => onClose()},
        ];
        const {imageSrc} = this.state;
        return (
            <Modal title={`${rejectingUser.alias} ${t("VIDEOCALL.REJECTED.Title")}`}
                   avatar={imageSrc ? (
                       <div className={styles.avatar}>
                           <Avatar image={imageSrc}
                                   name={rejectingUser.name}
                                   size="avatar-hard"
                                   status={rejectingUser.status}
                           />
                       </div>
                   ) : (
                       imageSrc && (
                           typeof imageSrc === 'string' ? (
                               <img src={imageSrc} alt="modal" className={styles.modalImage}/>
                           ) : (
                               <div className={styles.iconContainer}>
                                   {imageSrc}
                               </div>
                           )
                       )
                   )}
                   children={children}
                   titleClassName={styles.modalTitle}
                   buttons={buttons}
                   onClose={onClose}
            />
        );
    }
}

export default withTranslation()(RejectedVideoCall);
