import React, { Component } from "react";
import { Icon } from '@iconify/react';
import Modal from '../modal/modal.component';
import styles from './styles/modalConfirmation.module.scss';

class ModalConfirmation extends Component {
  handleClick = () => {
    this.props.onClose();
  };

  render() {
    const { customText, onClose, title, icon } = this.props;
    
    const iconComponent = (
      <div className={styles.iconContainer}>
        <Icon icon={icon} className={styles.icon} />
      </div>
    );

    return (
      <Modal
        title={title}
        titleClassName={styles.title}
        image={iconComponent}
        onClose={onClose}
      >
        <p className={styles.messages}>{customText}</p>
      </Modal>
    );
  }
}

export default ModalConfirmation;
