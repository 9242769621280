import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import { deleteUser } from '../../../services/UserService';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import styles from './styles/deleteUsers.module.scss';
import { Icon } from '@iconify/react';

class DeleteUsers extends Component {
  state = {
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    isLoading: false,
  };

  handleDelete = async () => {
    this.setState({ isLoading: true });
    try {
      await deleteUser(this.props.userId);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'ok',
        feedbackMessage: this.props.t("USERS.DELETE.SuccessMessage"),
      });
    } catch (error) {
      console.error('Error eliminando el usuario', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("USERS.DELETE.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    this.props.onClose(this.state.feedbackType === 'ok');
  };

  render() {
    const { t, onClose } = this.props;
    const { isFeedbackModalOpen, feedbackType, feedbackMessage, isLoading } = this.state;

    const buttons = [
      { label: t("USERS.DELETE.DeleteButton"), className: styles.deleteButton, onClick: this.handleDelete },
      { label: t("USERS.DELETE.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
    ];

    const icon = <Icon icon="bxs:error" className={styles.icon} />;

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal title={t("USERS.DELETE.Title")} titleClassName={styles.modalTitle} buttons={buttons} image={icon} onClose={() => onClose(false)}>
          <div className={styles.messages}>
            <p>{t("USERS.DELETE.Message1")}</p>
            <p>{t("USERS.DELETE.Message2")}</p>
          </div>
        </Modal>
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(DeleteUsers);
