import React, { Component } from "react";
import styles from './styles/notificationProfile.module.scss';
import { withTranslation } from "react-i18next";

class NotificationProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                soundNotification: false,
                contactConnected: false,
                contactOnline: false,
                callNotifications: false,
                emailNotifications: false,
            },
        };
    }

    handleCheckboxChange = (field) => {
        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                [field]: !prevState.fields[field],
            },
        }));
    };

    render() {
        const { t } = this.props;
        const { fields } = this.state;

        return (
            <div className={styles.notificationProfile}>
                <div className={styles.title}>{t("NOTIFICATION.TITLE.Notifications")}</div>
                <table className={styles.table}>
                    <tbody>
                        <tr className={`${styles.tableRow} ${styles.disabled}`}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("NOTIFICATION.LABEL.SoundNotification")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <input
                                    type="checkbox"
                                    checked={fields.soundNotification}
                                    className={styles.checkbox}
                                    onChange={() => this.handleCheckboxChange("soundNotification")}
                                    disabled={true}

                                />
                            </td>
                        </tr>
                        <tr className={`${styles.tableRow} ${styles.disabled}`}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("NOTIFICATION.LABEL.ContactConnected")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <input
                                    type="checkbox"
                                    checked={fields.contactConnected}
                                    className={styles.checkbox}
                                    onChange={() => this.handleCheckboxChange("contactConnected")}
                                    disabled={true}

                                />
                            </td>
                        </tr>
                        <tr className={`${styles.tableRow} ${styles.disabled}`}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("NOTIFICATION.LABEL.ContactOnline")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <input
                                    type="checkbox"
                                    checked={fields.contactOnline}
                                    className={styles.checkbox}
                                    onChange={() => this.handleCheckboxChange("contactOnline")}
                                    disabled={true}

                                />
                            </td>
                        </tr>
                        <tr className={`${styles.tableRow} ${styles.disabled}`}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("NOTIFICATION.LABEL.CallNotifications")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <input
                                    type="checkbox"
                                    checked={fields.callNotifications}
                                    className={styles.checkbox}
                                    onChange={() => this.handleCheckboxChange("callNotifications")}
                                    disabled={true}

                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={styles.title}>{t("NOTIFICATION.TITLE.EmailNotifications")}</div>
                <table className={styles.table}>
                    <tbody>
                        <tr className={`${styles.tableRow} ${styles.disabled}`}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("NOTIFICATION.LABEL.EmailNotifications")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <input
                                    type="checkbox"
                                    checked={fields.emailNotifications}
                                    className={styles.checkbox}
                                    onChange={() => this.handleCheckboxChange("emailNotifications")}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={`${styles.row} ${styles.buttonRow}`}>
                    <div className={styles.buttonGroup}>
                        <button className={styles.saveButton}>{t("NOTIFICATION.LABEL.SaveChanges")}</button>
                        <button className={styles.cancelButton}>{t("NOTIFICATION.LABEL.Cancel")}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NotificationProfile);
