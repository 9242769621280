import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import {calculateTimeDifference, getCurrentDate, getFormattedTimeFromTimestamp} from '../../utils/date.util';
import {Icon} from '@iconify/react';
import styles from './styles/notificationCard.module.scss';

class NotificationCard extends Component {

    getRandomBlueColor() {
        const blueColors = ["#00b4e6", "#4169e1", "#1e90ff"];
        const randomIndex = Math.floor(Math.random() * blueColors.length);
        return blueColors[randomIndex];
    }

    renderAvatar(image, title) {
        if (!image) {
            const initials = title.charAt(0).toUpperCase();
            return (
                <div className={`${styles.avatar} ${styles.avatarCircular}`} style={{ backgroundColor: this.getRandomBlueColor() }}>
                    <span>{initials}</span>
                </div>
            );
        } else {
            return <img src={image} alt="" className={styles.avatar} />;
        }
    }

    render() {
        const {title, body, date, readed, withDay, t, onClick } = this.props;

        return (
            <div className={`${styles.notification} ${readed ? styles.highlighted : ""}`} onClick={onClick}>
                <div className={styles.column1}>
                    <div className={styles.notificationContent}>
                        {/*this.renderAvatar(image, title)*/}
                        <div className={styles.notificationDetails}>
                            <div className={styles.notificationTime}>
                                <span>{calculateTimeDifference(date, t)}</span>
                                <Icon icon="tabler:point-filled" width="10" height="10" />
                                <span>{getFormattedTimeFromTimestamp(date)}</span>
                            </div>
                            <div className={styles.notificationMessage}>{title}</div>
                            <div className={styles.notificationBody}>
                                {body.length > 30 ? `${body.substring(0, 30)}...` : body}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={styles.column2}>
                    {withDay && <span>{getCurrentDate(date, t)}</span>}
                    {!readed && <div className={styles.blueDot} />}
                </div>
            </div>
        );
    }
}

export default withTranslation()(NotificationCard);
