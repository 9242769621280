import {Client} from '@stomp/stompjs';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

const WebSocketService = {
  stompClient: null,
  connectionState: 'DISCONNECTED',
  subscriptions: [],
  pendingMessages: [],
  reconnectAttempts: 0,
  reconnectTimer: null,
  isReconnecting: false,
  reconnectDelay: 5000,
  maxReconnectAttempts: 5,

  connectToStomp() {
    return new Promise((resolve, reject) => {
      if (this.connectionState === 'CONNECTED') {
        console.log('Ya está conectado al WebSocket.');
        resolve(); // Si ya está conectado, no hacer nada
        return;
      }

      this.connectionState = 'CONNECTING';

      this.stompClient = new Client({
        brokerURL: WEBSOCKET_URL,
        connectHeaders: {
          'accept-version': '1.2',
          'heart-beat': '10000,10000', // Heartbeat intervals
        },
        debug: (str) => {
          if (!str.includes('PING') && !str.includes('PONG')) {
            console.log(str);
          }
        },
        reconnectDelay: 0, // We handle reconnection manually
        onConnect: () => {
          this.connectionState = 'CONNECTED';
          this.reconnectAttempts = 0;
          this.isReconnecting = false;

          console.log('Conexión WebSocket establecida.');
          // Resuscribir tópicos y enviar mensajes pendientes
          this.subscriptions.forEach(subscription => {
            subscription.subscription = this.stompClient.subscribe(subscription.topic, subscription.callback);
          });
          this.pendingMessages.forEach(({ destination, body, headers }) => {
            try {
                this.stompClient.publish({ destination, headers, body });
                console.log("[MESSAGE SENT]", { destination, body, headers });
            } catch (error) {
              console.error('Error enviando mensaje pendiente:', error);
            }
          });
          this.pendingMessages = [];

          resolve();
        },

        onStompError: (frame) => {
          console.error('Error STOMP:', frame.headers['message']);
          this.reconnect(); // Intentar reconectar si hay un error
          reject(frame);
        },

        onWebSocketClose: () => {
          if (this.connectionState !== 'DISCONNECTED') {
            this.connectionState = 'DISCONNECTED';
            if (!this.isReconnecting) {
              this.isReconnecting = true;
              console.warn('WebSocket cerrado. Intentando reconectar...');
              this.reconnect(); // Intentar reconectar si la conexión se cierra
            }
          }
        },
      });

      this.stompClient.activate();
    });
  },


  subscribeToTopic(topic, callback) {
    if (this.connectionState !== 'CONNECTED') {
      console.error('El cliente Stomp no está conectado. Guardando la suscripción para cuando esté disponible.');
      this.subscriptions.push({ topic, callback });
      return;
    }

    // Cancelar suscripciones previas al mismo topic
    const existingSubscriptionIndex = this.subscriptions.findIndex(sub => sub.topic === topic);
    if (existingSubscriptionIndex !== -1) {
      console.log(`Cancelando suscripción previa para el tópico: ${topic}`);
      this.subscriptions[existingSubscriptionIndex].subscription.unsubscribe();
      this.subscriptions.splice(existingSubscriptionIndex, 1);
    }

    try {
      const subscription = this.stompClient.subscribe(topic, callback);
      this.subscriptions.push({ topic, callback, subscription });
      return subscription;
    } catch (error) {
      console.error(`Error al suscribirse al tópico ${topic}:`, error);
    }
  },



  send(destination, body, headers = {}) {
    if (this.connectionState !== 'CONNECTED') {
      console.error('El cliente Stomp no está conectado, mensaje en cola');
      this.pendingMessages.push({ destination, body, headers });
      return;
    }
    try {
      this.stompClient.publish({ destination, headers, body });
      console.log("[MESSAGE SENT]", { destination, body, headers });
    } catch (error) {
      console.error('Error enviando mensaje:', error);
      this.pendingMessages.push({ destination, body, headers });
      this.reconnect();
    }
  },

  disconnect() {
    if (this.connectionState === 'CONNECTED' && this.stompClient) {
      // Desuscribirse de todos los topics antes de desconectar
      this.subscriptions.forEach(sub => {
        sub.subscription.unsubscribe();
      });
      this.subscriptions = [];
      this.stompClient.deactivate();
      this.connectionState = 'DISCONNECTED';
    }
    clearTimeout(this.reconnectTimer);
  },


  reconnect() {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error('Se superó el número máximo de intentos de reconexión');
      return;
    }

    this.reconnectTimer = setTimeout(() => {
      this.reconnectAttempts++;
      console.log(`Intentando reconectar al WebSocket (Intento ${this.reconnectAttempts})...`);
      this.connectToStomp().then(() => {
        this.isReconnecting = false;
        console.log('Reconexión exitosa. Re-suscribiendo a los tópicos...');
      }).catch((error) => {
        console.error('Error en la reconexión:', error);
        this.reconnect(); // Volver a intentar si falla
      });
    }, this.reconnectDelay);
  },

};

export default WebSocketService;
