export const errorHandler = (statusResponse) => {
    try {
        if (statusResponse === 401) {
            console.log('401 Unauthorized error');
            // Redirect to login page
            window.location.href = '/login';
            throw new Error('Unauthorized');
        }
    } catch (error) {
        console.error('Error handling 401 Unauthorized error:', error);
        throw error;
    }
};
