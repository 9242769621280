import React, { Component } from "react";
import Avatar from '../avatar/avatar.component';
import { withTranslation } from 'react-i18next';
import {getStateTranslation, getUserTypeTranslation} from '../../utils/translation.util.js';
import styles from './styles/contactCard.module.scss';
import { getImage } from '../../services/BlobService';

import {Icon} from "@iconify/react";
import {State} from "../../utils/StateEnum";

const getStatusIcon = (state) => {
  switch (state) {
    case State.ONLINE:
      return (
          <Icon icon="lets-icons:check-fill" className={styles.green} width="16" height="16" />
      );
    case State.OFFLINE:
      return (
          <Icon icon="entypo:circle-with-cross" className={styles.grey} width="16" height="16" />
      );
    case State.BUSY:
      return (
          <Icon icon="tdesign:minus-circle-filled" className={styles.red} width="16" height="16" />
      );
    default:
      return null;
  }
};
const getStatusClass = (state) => {
  switch (state) {
    case State.ONLINE:
      return styles.green;
    case State.OFFLINE:
      return styles.grey;
    case State.BUSY:
      return styles.red;
    default:
      return "";
  }
};

class StateBadge extends Component {
  render() {
    const { state, t } = this.props;
    return (
        <div className={styles.statusBadgeContainer}>
          {getStatusIcon(state)}<div className={`${styles.stateText} ${getStatusClass(state)}`}>{getStateTranslation(t, state)}</div>
        </div>
    );
  }
}

class ContactCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null
    };
  }

  fetchImage = async (imageId) => {
    if (imageId) {
      try {
        const url = await getImage(imageId);
        this.setState({ imageSrc: url });
      } catch (error) {
        console.error('Error fetching image:', error);
        this.setState({ imageSrc: null });
      }
    } else {
      this.setState({ imageSrc: null });
    }
  };

  componentDidMount() {
    const { contact } = this.props;
    this.fetchImage(contact.avatar);
  }

  render() {
    const { contact, t } = this.props;
    const { imageSrc } = this.state;

    return (
        <div className={styles.container} onClick={this.props.onClick}>
          <section className={styles.statusSection}>
            <StateBadge state={contact.state} t={t}/>
          </section>
          <section className={styles.contactSection}>
            <div className={styles.contactInfo}>
              <Avatar
                  image={imageSrc}
                  name={contact.name}
                  size='avatar-hard'
                  status={contact.state}
                  backgroundColor={contact.backgroundColor}
              />
              <div className={styles.contactDetails}>
                <div className={styles.contactHeader}>
                  <div className={styles.nameUserType}>
                    <div className={styles.userType}>{getUserTypeTranslation(t, contact.userType)}</div>
                    <div className={styles.name}>
                      <span>{contact.name} {contact.surname}</span>
                      {contact.lastVideoCall && (
                          <span className={styles.dateCall}>{contact.lastVideoCall.day}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={styles.miscSection}>
            <div className={styles.niu}>{contact.homes[0].niu}</div>
            <Icon icon="lets-icons:check-fill" className={styles.infoIcon} width="25" height="25"/>
            <div className={styles.homeName}>{contact.homes[0].name}</div>
          </section>
        </div>
    );
  }
}

export default withTranslation()(ContactCard);
