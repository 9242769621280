import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationCard from '../notification-card/notificationCard.component';
import NotificationCreate from '../notification-create/notificationCreate.component';
import NotificationDetail from '../notification-detail/notificationDetail.component';
import { withTranslation } from 'react-i18next';
import { getNotifications, markNotificationAsRead } from '../../services/notification/NotificationService';
import { setNotifications, addNotifications, incrementOffset, setHasMore, markNotificationAsRead as markNotiAsRead } from '../../redux/slices/notificationSlice';
import styles from './styles/notificationAll.module.scss';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

const BackIcon = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <Icon icon="ic:round-arrow-back" className={styles.backIcon} onClick={handleBackClick} />
    );
};

class NotificationAll extends Component {
    state = {
        selectedNotification: null,
        isModalOpen: false,
    };

    async componentDidMount() {
        await this.loadNotifications();
    }

    loadNotifications = async () => {
        const { offset, incrementOffset, setHasMore, addNotifications } = this.props;
        try {
            await new Promise(resolve => setTimeout(resolve, 1000));
            const limit = 15;
            const notifications = await getNotifications(limit, offset);
            console.log("notifications:", notifications);
            if (notifications.length > 0) {
                addNotifications(notifications);
                incrementOffset(limit);
                if (notifications.length < limit) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error loading notifications:', error);
        }
    };


    openNotificationDetail = async (notification) => {
        try {
            await markNotificationAsRead(notification.id);
            this.setState({ selectedNotification: notification, isModalOpen: true });
            await this.props.markNotiAsRead(notification.id);
        } catch (error) {
            console.error('Error al marcar la notificación como leída:', error);
        }
    };


    closeNotificationDetail = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { t, notifications, hasMore } = this.props;
        const { selectedNotification, isModalOpen } = this.state;

        return (
            <div className={styles.notificationAllWrapper}>
                <div className={styles.header}>
                    <BackIcon />
                    <NotificationCreate />
                </div>
                <div className={styles.notificationsList} id="notList">
                    <InfiniteScroll
                        dataLength={notifications.length}
                        next={this.loadNotifications}
                        hasMore={hasMore}
                        loader={<div className={styles.spinnerContainer}><div className={styles.spinner}></div></div>}
                        scrollableTarget="notList"
                        endMessage={<p className={styles.endMessage}>{t('NOTIFICATIONS.LABEL.NoMoreNotifications')}</p>}
                    >
                        {notifications.length === 0 ? (
                            <p className={styles.emptyNotificationMessage}>{t('NOTIFICATIONS.LABEL.EmptyNotifications')}</p>
                        ) : (
                            notifications.map((notification, index) => (
                                <NotificationCard
                                    key={index}
                                    {...notification}
                                    withDay={true}
                                    onClick={() => this.openNotificationDetail(notification)}
                                />
                            ))
                        )}
                    </InfiniteScroll>
                </div>

                {isModalOpen && (
                    <NotificationDetail
                        notification={selectedNotification}
                        onClose={this.closeNotificationDetail}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
    offset: state.notifications.offset,
    hasMore: state.notifications.hasMore,
});

const mapDispatchToProps = {
    setNotifications,
    addNotifications,
    incrementOffset,
    setHasMore,
    markNotiAsRead,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationAll));
