import React, { Component } from "react";
import { Icon } from '@iconify/react';
import { State } from '../../utils/StateEnum.js'
import './styles/avatar.scss';
class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderAvatar(image, name = "User", size, status, backgroundColor) {

    let iconSize = 16;
    let containerSize = 16;
    switch (size) {
      case "avatar-medium":
        iconSize = 20;
        containerSize = 20;
        break;
      case "avatar-hard":
        iconSize = 25;
        containerSize = 25;
        break;
      case "avatar-very-hard":
        iconSize = 35;
        containerSize = 35;
        break;
      default:
        break;
    }

    const statusIcon = () => {
      switch (status) {
        case State.ONLINE:
          return <Icon icon="mdi:tick-circle" width={iconSize} height={iconSize} className="online" />;
        case State.BUSY:
          return <Icon icon="tdesign:minus-circle-filled" width={iconSize} height={iconSize} className="busy" />;
        case State.OFFLINE:
          return <Icon icon="mdi:close-circle" width={iconSize} height={iconSize} className="offline" />;
        default:
          return null;
      }
    };

    if (!image) {
      const initials = name.charAt(0).toUpperCase();
      return (
        <div
          className={`avatar avatar-circular ${size}`}
          style={{ backgroundColor: backgroundColor }}
        >
          <span>{initials}</span>
          {status && (
            <div className="avatar-status">
              <div className="status-icon-container" style={{ width: containerSize, height: containerSize }}>
                {statusIcon()}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={`avatar ${size}`}>
          <img src={image} alt="" className={`${size}`} />
          {status && (
            <div className="avatar-status">
              <div className="status-icon-container" style={{ width: containerSize, height: containerSize }}>
                {statusIcon()}
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  render() {
    const { image, name = "User", size, status, backgroundColor } = this.props;
    return this.renderAvatar(image, name, size, status, backgroundColor);
  }
}

export default Avatar;
