import React, {Component} from "react";
import styles from './styles/userProfile.module.scss';
import {withTranslation} from "react-i18next";
import {Icon} from '@iconify/react';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';
import CustomInput from '../../custom-input/customInput.component';
import {getUserTypes} from '../../../services/UserTypeService';
import {searchHomes} from '../../../services/HomeService';
import {getUsersByEmail, updateUserByUser} from '../../../services/UserService';
import {getImage} from '../../../services/BlobService';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import {connect} from 'react-redux';
import {setUserInfo} from '../../../redux/slices/userSlice';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import CustomModal from "../../custom-modal/customModal.component";
import LoadingScreen from '../../loading-screen/loadingScreen.component';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: greyImage,
      image: null,
      isUploadModalOpen: false,
      selectedFile: null,
      fields: {
        name: "",
        surname: "",
        email: "",
        birthDate: "",
        direction: "",
        locality: "",
        postalCode: "",
        active: true,
      },
      editingField: null,
      fieldValues: {},
      userTypes: [],
      homes: [],
      selectedUserType: null,
      selectedHomes: [],
      isLoadingImage: true,
      isSavingProfile: false,
      isFeedbackModalOpen: false,
      feedbackType: null,
      feedbackMessage: '',
      showUpdateConfirmationModal: false
    };
  }

  async componentDidMount() {
    try {
      const userTypes = await getUserTypes();
      this.setState({
        userTypes: userTypes.map(type => ({ key: type.id, value: this.props.t("USER_TYPE." + type.name) }))
      });
      const homes = await this.searchHomes("");
      this.setState({ homes });

      const user = await getUsersByEmail(this.props.email);
      if (user) {
        this.setState({
          fields: {
            name: user.name,
            surname: user.surname,
            email: user.email,
            birthDate: user.birthDate || "",
            direction: user.direction || "",
            locality: user.locality || "",
            postalCode: user.postalCode || "",
            active: user.active
          },
          selectedUserType: { key: user.userType.id, value: this.props.t("USER_TYPE." + user.userType.name) },
          selectedHomes: user.homeIds.map(id => homes.find(home => home.key === id))
        });
        if (user.avatar) {
          this.fetchImage(user.avatar);
        } else {
          this.setState({ isLoadingImage: false });
        }
      }
    } catch (error) {
      console.error('Error obteniendo tipos de usuario y hogares:', error);
      this.setState({ isLoadingImage: false });
    }
  }

  fetchImage = async (imageId) => {
    if (imageId) {
      this.setState({ isLoadingImage: true });
      try {
        const url = await getImage(imageId);
        this.setState({ imageSrc: url, image: url, isLoadingImage: false });
      } catch (error) {
        console.error('Error fetching image:', error);
        this.setState({ isLoadingImage: false });
      }
    } else {
      this.setState({ isLoadingImage: false });
    }
  };

  handleImageMouseEnter = () => {
    if (this.state.image) return;
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.image) return;
    this.setState({ imageSrc: this.state.image || greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  handleEditClick = (field) => {
    this.setState({
      editingField: field,
      fieldValues: { ...this.state.fields }
    });
  };

  handleCancelClick = () => {
    this.setState({ editingField: null });
  };

  handleSaveClick = (field) => {
    if (field === "name") {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          name: prevState.fieldValues.name,
          surname: prevState.fieldValues.surname
        },
        editingField: null
      }));
    } else {
      this.setState((prevState) => ({
        fields: { ...prevState.fields, [field]: prevState.fieldValues[field] },
        editingField: null
      }));
    }
  };

  handleInputChange = (value, field) => {
    this.setState({
      fieldValues: { ...this.state.fieldValues, [field]: value }
    });
  };

  setSelectedUserType = (selectedOptions) => {
    this.setState({ selectedUserType: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  };

  setSelectedHomes = async (selectedOptions) => {
    this.setState({ selectedHomes: Array.isArray(selectedOptions) ? selectedOptions : [] });
  };

  searchHomes = async (term) => {
    try {
      const homes = await searchHomes(term);
      return homes.map(home => ({ key: home.id, value: home.name }));
    } catch (error) {
      console.error('Error buscando hogares:', error);
      return [];
    }
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
  };

  handleShowConfirmation = () => {
    this.setState({ showUpdateConfirmationModal: true });
  };

  handleCancelUpdate = () => {
    this.setState({ showUpdateConfirmationModal: false });
  };

  handleSaveProfile = async () => {
    const { fields, selectedUserType, selectedHomes, selectedFile } = this.state;
    const data = {
      ...fields,
      file: selectedFile || null,
      userTypeId: selectedUserType.key,
      homeIds: selectedHomes.map(home => home.key),
      avatar: selectedFile
    };
    this.setState({ showUpdateConfirmationModal: false, isSavingProfile: true })

    try {
      const user = await updateUserByUser(data);
      this.props.setUserInfo({ email: user.email, id: user.id, avatar: user.avatar });
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'ok',
        feedbackMessage: this.props.t("PROFILE.LABEL.SuccessMessage"),
      });
    } catch (error) {
      console.error('Error actualizando perfil:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("PROFILE.LABEL.ErrorMessage"),
      });
    } finally {
      this.setState({ isSavingProfile: false });
    }
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file) });
  };

  render() {
    const { t } = this.props;
    const { fields, editingField, fieldValues,isLoadingImage, isSavingProfile, imageSrc, isUploadModalOpen, feedbackMessage, showUpdateConfirmationModal, feedbackType, isFeedbackModalOpen } = this.state;

    const updateConfirmationButtons = [
      { label: t("CONTENT.CREATE.ConfirmButton"), className: styles.saveButton, onClick: this.handleSaveProfile },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: this.handleCancelUpdate }
    ];

    return (
      <div className={styles.profileView}>
        {isSavingProfile && <LoadingScreen />}
        <div className={styles.title}>{t("PROFILE.LABEL.Title")}</div>
        <div className={styles.row}>
          <div className={styles.imageFormHeader}>
            <label className={styles.label}>{t("PROFILE.LABEL.Image")}</label>
            <span className={styles.editLabel} onClick={this.handleImageClick}>{t("PROFILE.LABEL.Edit")}</span>
          </div>
          <div className={styles.imageContainer}>
            {isLoadingImage ? (
              <div className={styles.loadingIndicator}>
                <Icon icon="eos-icons:loading" width="45" height="45" />
              </div>
            ) : (
              <img
                alt=""
                className={styles.image}
                src={imageSrc}
                onMouseEnter={this.handleImageMouseEnter}
                onMouseLeave={this.handleImageMouseLeave}
                onClick={this.handleImageClick}
              />
            )}
          </div>
        </div>

        <table className={styles.table}>
          <tbody>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell3}>
                <div className={styles.label}>{t("PROFILE.LABEL.Name")}</div>
              </td>
              <td className={styles.tableCell3}>
                {editingField === "name" ? (
                  <>
                    <CustomInput
                      type="text"
                      name="name"
                      value={fieldValues.name}
                      onChange={(value) => this.handleInputChange(value, "name")}
                      customClass={styles.inputField}
                    />
                    <CustomInput
                      type="text"
                      name="surname"
                      value={fieldValues.surname}
                      onChange={(value) => this.handleInputChange(value, "surname")}
                      customClass={styles.inputField}
                    />
                  </>
                ) : (
                  <div className={styles.value}>{fields.name} {fields.surname}</div>
                )}
              </td>
              <td className={styles.tableCell3}>
                {editingField === "name" ? (
                  <div className={styles.buttonGroup}>
                    <span className={styles.editLabel} onClick={() => this.handleSaveClick("name")}>{t("PROFILE.LABEL.Save")}</span>
                    <span className={styles.editLabel} onClick={this.handleCancelClick}>{t("PROFILE.LABEL.Cancel")}</span>
                  </div>
                ) : (
                  <span className={styles.editLabel} onClick={() => this.handleEditClick("name")}>{t("PROFILE.LABEL.Edit")}</span>
                )}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell3}>
                <div className={styles.label}>{t("PROFILE.LABEL.Email")}</div>
              </td>
              <td className={styles.tableCell3}>
                <div className={styles.valueEmail}>{fields.email}</div>
              </td>
              <td className={styles.tableCell3}></td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell3}>
                <div className={styles.label}>{t("PROFILE.LABEL.BirthDate")}</div>
              </td>
              <td className={styles.tableCell3}>
                {editingField === "birthDate" ? (
                  <CustomInput
                    type="date"
                    name="birthDate"
                    value={fieldValues.birthDate}
                    onChange={(value) => this.handleInputChange(value, "birthDate")}
                    customClass={styles.inputField}
                  />
                ) : (
                  <div className={styles.value}>{fields.birthDate}</div>
                )}
              </td>
              <td className={styles.tableCell3}>
                {editingField === "birthDate" ? (
                  <div className={styles.buttonGroup}>
                    <span className={styles.editLabel} onClick={() => this.handleSaveClick("birthDate")}>{t("PROFILE.LABEL.Save")}</span>
                    <span className={styles.editLabel} onClick={this.handleCancelClick}>{t("PROFILE.LABEL.Cancel")}</span>
                  </div>
                ) : (
                  <span className={styles.editLabel} onClick={() => this.handleEditClick("birthDate")}>{t("PROFILE.LABEL.Edit")}</span>
                )}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell3}>
                <div className={styles.label}>{t("PROFILE.LABEL.Locality")}</div>
              </td>
              <td className={styles.tableCell3}>
                {editingField === "locality" ? (
                  <CustomInput
                    type="text"
                    name="locality"
                    value={fieldValues.locality}
                    onChange={(value) => this.handleInputChange(value, "locality")}
                    customClass={styles.inputField}
                  />
                ) : (
                  <div className={styles.value}>{fields.locality}</div>
                )}
              </td>
              <td className={styles.tableCell3}>
                {editingField === "locality" ? (
                  <div className={styles.buttonGroup}>
                    <span className={styles.editLabel} onClick={() => this.handleSaveClick("locality")}>{t("PROFILE.LABEL.Save")}</span>
                    <span className={styles.editLabel} onClick={this.handleCancelClick}>{t("PROFILE.LABEL.Cancel")}</span>
                  </div>
                ) : (
                  <span className={styles.editLabel} onClick={() => this.handleEditClick("locality")}>{t("PROFILE.LABEL.Edit")}</span>
                )}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell3}>
                <div className={styles.label}>{t("PROFILE.LABEL.Direction")}</div>
              </td>
              <td className={styles.tableCell3}>
                {editingField === "direction" ? (
                  <CustomInput
                    type="text"
                    name="direction"
                    value={fieldValues.direction}
                    onChange={(value) => this.handleInputChange(value, "direction")}
                    customClass={styles.inputField}
                  />
                ) : (
                  <div className={styles.value}>{fields.direction}</div>
                )}
              </td>
              <td className={styles.tableCell3}>
                {editingField === "direction" ? (
                  <div className={styles.buttonGroup}>
                    <span className={styles.editLabel} onClick={() => this.handleSaveClick("direction")}>{t("PROFILE.LABEL.Save")}</span>
                    <span className={styles.editLabel} onClick={this.handleCancelClick}>{t("PROFILE.LABEL.Cancel")}</span>
                  </div>
                ) : (
                  <span className={styles.editLabel} onClick={() => this.handleEditClick("direction")}>{t("PROFILE.LABEL.Edit")}</span>
                )}
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell3}>
                <div className={styles.label}>{t("PROFILE.LABEL.PostalCode")}</div>
              </td>
              <td className={styles.tableCell3}>
                {editingField === "postalCode" ? (
                  <CustomInput
                    type="text"
                    name="postalCode"
                    value={fieldValues.postalCode}
                    onChange={(value) => this.handleInputChange(value, "postalCode")}
                    customClass={styles.inputField}
                  />
                ) : (
                  <div className={styles.value}>{fields.postalCode}</div>
                )}
              </td>
              <td className={styles.tableCell3}>
                {editingField === "postalCode" ? (
                  <div className={styles.buttonGroup}>
                    <span className={styles.editLabel} onClick={() => this.handleSaveClick("postalCode")}>{t("PROFILE.LABEL.Save")}</span>
                    <span className={styles.editLabel} onClick={this.handleCancelClick}>{t("PROFILE.LABEL.Cancel")}</span>
                  </div>
                ) : (
                  <span className={styles.editLabel} onClick={() => this.handleEditClick("postalCode")}>{t("PROFILE.LABEL.Edit")}</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={`${styles.row} ${styles.buttonRow}`}>
          <div className={styles.buttonGroup}>
            <button className={styles.saveButton} onClick={this.handleShowConfirmation}>{t("PROFILE.LABEL.Save")}</button>
            <button className={styles.cancelButton}>{t("PROFILE.LABEL.Cancel")}</button>
          </div>
        </div>
        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}
        {showUpdateConfirmationModal && (
          <CustomModal
            title={t("PROFILE.LABEL.EditConfirmationTitle")}
            customText={t("PROFILE.LABEL.EditConfirmationMessage")}
            icon="mdi:account-edit"
            iconClassName={styles.updateIcon}
            buttons={updateConfirmationButtons}
            onClose={this.handleCancelUpdate}
          />
        )}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.user.email,
});

const mapDispatchToProps = {
  setUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserProfile));
