import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timeZone: 'Europe/Madrid',
};

const timeZoneSlice = createSlice({
  name: 'timeZone',
  initialState,
  reducers: {
    setTimeZone(state, action) {
      state.timeZone = action.payload;
    },
    clearTimeZone(state) {
      state.timeZone = 'Europe/Madrid';
    },
  },
});

export const { setTimeZone, clearTimeZone } = timeZoneSlice.actions;
export default timeZoneSlice.reducer;
