import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import styles from "./styles/selectButton.module.scss";
import { withTranslation } from "react-i18next";

class SelectButton extends Component {
    render() {
        const { icon, label, selectedCount, onClick, t } = this.props;

        const buttonClass = selectedCount > 0 ? `${styles.selectButton} ${styles.active}` : styles.selectButton;

        return (
            <button className={buttonClass} onClick={onClick}>
                <Icon icon={icon} className={styles.icon} />
                <span className={styles.text}>
                    {selectedCount > 0 ? `+${selectedCount} ${label}...` : `${t("NOTIFICATIONS.LABEL.Select")} ${label}`}
                </span>
                <Icon icon="mdi:plus" className={styles.plusIcon} />
            </button>
        );
    }
}

SelectButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selectedCount: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

SelectButton.defaultProps = {
    selectedCount: 0,
};

export default withTranslation()(SelectButton);
