import React, { Component } from "react";
import './styles/simpleFooter.scss'
import { withTranslation } from 'react-i18next';

class SimpleFooter extends Component {
  render() {    
    const { t } = this.props;
    return (
      <footer className="simple-footer">
        <section className="help-section">
          <p className="help-text">{t('SIMPLE_FOOTER.LABEL.FailSession')}</p>
          <p className="help-link">{t('SIMPLE_FOOTER.LABEL.Help')}</p>
        </section>
      </footer>
    );
  }
}

export default withTranslation()(SimpleFooter);
