import { getToken, onMessage, deleteToken } from "firebase/messaging";
import { messaging } from '../FirebaseConfig.js';
import { store } from '../../redux/store.js';
import { setSession } from '../../redux/slices/sessionSlice.js';
import { addNotificationToStart } from '../../redux/slices/notificationSlice.js';
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const FirebaseService = {
    requestToken: async () => {
        let currentToken = '';
        try {
            currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
            if (currentToken) {
                console.log('Current token:', currentToken);
                store.dispatch(setSession(currentToken));
                await FirebaseService.sendDeviceToken(currentToken, store.getState().user.id);
                return currentToken;
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        } catch (error) {
            console.log('An error occurred while retrieving token: ', error);
        }
        return currentToken;
    },

    removeToken: async () => {
        try {
            const currentToken = store.getState().session.session;
            if (currentToken) {
                await deleteToken(messaging);
                console.log('Token deleted:', currentToken);
                await FirebaseService.deleteDeviceToken(currentToken);
                store.dispatch(setSession(null));
            } else {
                console.log('No registration token available.');
            }
        } catch (error) {
            console.log('An error occurred while deleting token: ', error);
        }
    },

    askNotificationPermission: async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                return true;
            } else {
                console.log('Notification permission denied.');
                return false;
            }
        } catch (error) {
            console.log('An error occurred while requesting notification permission: ', error);
            return false;
        }
    },

    onMessageListener: () => {
        return new Promise((resolve, reject) => {
            onMessage(messaging, (payload) => {
                console.log("Message received in foreground: ", payload);

                const notificationData = payload.data;

                if (notificationData) {
                    store.dispatch(addNotificationToStart(notificationData));
                } else {
                    console.error("Payload does not contain notification information", payload);
                }

                resolve(payload);
            }, (error) => {
                console.error('Error receiving foreground message:', error);
                reject(error);
            });
        });
    },

    onBackgroundMessageListener: () => {
        return new Promise((resolve, reject) => {
            navigator.serviceWorker.addEventListener('message', (event) => {
                console.log('Background message received: ', event);
                resolve(event.data);
            }, (error) => {
                reject(error);
            });
        });
    },

    sendDeviceToken: async (token) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let authToken = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/sessions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    token: token,
                    type: "web",
                })
            });

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to send device token to backend');
        } catch (error) {
            console.error('Error sending device token:', error);
            throw error;
        }
    },

    deleteDeviceToken: async (token) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let authToken = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/sessions/${token}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to delete device token from backend');
        } catch (error) {
            console.error('Error deleting device token:', error);
            throw error;
        }
    }
};

export const { requestToken, removeToken, askNotificationPermission, onMessageListener, onBackgroundMessageListener, sendDeviceToken, deleteDeviceToken } = FirebaseService;
