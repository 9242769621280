import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import styles from './styles/changeAdministratorUser.module.scss';

class ChangeAdministratorUser extends Component {
    handleChange = async () => {
        const { id, newStatus, value } = this.props;
        try {
            await this.props.onStatusChange({ id, newStatus, value });
            this.props.onClose(true);
        } catch (error) {
            console.error('Error al cambiar el estado del usuario', error);
            this.props.onClose(false);
        }
    };

    render() {
        const { t, onClose, activate } = this.props;
        const buttons = [
            { label: t("USERS.CHANGEADMINISTRATOR.SaveButton"), className: styles.saveButton, onClick: this.handleChange },
            { label: t("USERS.CHANGEADMINISTRATOR.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
        ];
        let modal;
        if (activate) {
            modal =
                <Modal title={t("USERS.CHANGEADMINISTRATOR.TitleActivate")} titleClassName={styles.modalTitle} buttons={buttons}>
                    <div className={styles.messages}>
                        <p>{t("USERS.CHANGEADMINISTRATOR.Message1Activate")}</p>
                        <p>{t("USERS.CHANGEADMINISTRATOR.Message2Activate")}</p>
                    </div>
                </Modal>
        } else {
            modal =
                <Modal title={t("USERS.CHANGEADMINISTRATOR.TitleDeactivate")} titleClassName={styles.modalTitle} buttons={buttons}>
                    <div className={styles.messages}>
                        <p>{t("USERS.CHANGEADMINISTRATOR.Message1Deactivate")}</p>
                        <p>{t("USERS.CHANGEADMINISTRATOR.Message2Deactivate")}</p>
                    </div>
                </Modal>
        }

        return modal;
    }
}

export default withTranslation()(ChangeAdministratorUser);
