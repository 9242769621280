import {combineReducers} from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import languageReducer from './slices/languageSlice';
import timeZoneReducer from './slices/timeZoneSlice';
import userTypeReducer from './slices/userTypeSlice';
import contactReducer from './slices/contactSlice';
import profileReducer from './slices/profileSlice';
import videocallReducer from './slices/videocallSlice';
import layoutReducer from './slices/layoutSlice';
import tenantReducer from './slices/tenantSlice';
import sessionReducer from './slices/sessionSlice';
import notificationReducer from './slices/notificationSlice';
import contentReducer from "./slices/contentSlice";

const rootReducer = combineReducers({
  user: userReducer,
  language: languageReducer,
  timeZone: timeZoneReducer,
  userTypes: userTypeReducer,
  contact: contactReducer,
  profile: profileReducer,
  videocall: videocallReducer,
  layout: layoutReducer,
  tenant: tenantReducer,
  session: sessionReducer,
  notifications: notificationReducer,
  content: contentReducer,
});

export default rootReducer;
