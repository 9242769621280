import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Modal from "../../modal/modal.component";
import LoadingScreen from "../../loading-screen/loadingScreen.component";
import { Icon } from "@iconify/react";
import styles from "./styles/notificationSelectHomes.module.scss";
import { searchHomes } from "../../../services/HomeService";

class NotificationSelectHomes extends Component {
  state = {
    homes: [],
    selectedHomes: this.props.selectedHomes || [],
    searchTerm: "",
    isLoading: true,
  };

  componentDidMount() {
    this.searchHomes("");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedHomes !== this.props.selectedHomes) {
      this.setState({ selectedHomes: this.props.selectedHomes });
    }
  }

  searchHomes = async (term) => {
    this.setState({ isLoading: true });
    try {
      const homes = await searchHomes(term);
      this.setState({ homes, isLoading: false });
    } catch (error) {
      console.error("Error fetching homes:", error);
      this.setState({ isLoading: false });
    }
  };

  handleHomeToggle = (home) => {
    this.setState((prevState) => {
      const { selectedHomes } = prevState;
      const isSelected = selectedHomes.includes(home.id);

      const newSelectedHomes = isSelected
        ? selectedHomes.filter((id) => id !== home.id)
        : [...selectedHomes, home.id];

      return { selectedHomes: newSelectedHomes };
    });
  };

  handleCheckboxChange = (home, event) => {
    event.stopPropagation();
    this.handleHomeToggle(home);
  };

  handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm }, () => {
      this.searchHomes(searchTerm);
    });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.selectedHomes);
  };

  render() {
    const { t, onClose } = this.props;
    const { homes, selectedHomes, isLoading, searchTerm } = this.state;

    const buttons = [
      {
        label: t("NOTIFICATIONS.LABEL.ConfirmButton"),
        className: styles.confirmButton,
        onClick: this.handleSubmit,
      },
      {
        label: t("NOTIFICATIONS.LABEL.CancelButton"),
        className: styles.cancelButton,
        onClick: onClose,
      },
    ];

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal
          title={t("NOTIFICATIONS.LABEL.TitleSelectHomes")}
          titleClassName={styles.modalTitle}
          buttons={buttons}
        >
          <div className={`${styles.searchContainer} ${searchTerm ? styles.active : ''}`}>
            <input
              className={styles.searchInput}
              type="text"
              value={searchTerm}
              onChange={this.handleSearchChange}
              placeholder={t('NOTIFICATIONS.LABEL.SearchHomesPlaceholder')}
            />
            <Icon icon="lucide:search" width="20px" height="20px" className={styles.searchIcon} />
          </div>
          <div className={styles.userListContainer}>
            {homes.map((home) => (
              <div
                key={home.id}
                className={`${styles.userItem} ${selectedHomes.includes(home.id) ? styles.selected : ""}`}
                onClick={() => this.handleHomeToggle(home)}
              >
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  checked={selectedHomes.includes(home.id)}
                  onChange={(event) => this.handleCheckboxChange(home, event)}
                  onClick={(event) => event.stopPropagation()}
                />
                <div className={styles.homeInfo}>
                  <div className={styles.homeTitle}>{home.name}</div>
                  <div className={styles.homeId}>{home.niu}</div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(NotificationSelectHomes);
