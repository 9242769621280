export const playRingtone = (play) => {
    var audio = document.getElementById("ringtone");
    if (!audio) {
        return;
    }
    if (play) {
        try {
            audio.play();
        } catch (error) {
            console.error('Error playing the videocall ringtone:', error);
            throw error;
        }
    } else {
        try {
            audio.pause();
            audio.currentTime = 0;
        } catch (error) {
            console.error('Error stopping the videocall ringtone:', error);
            throw error;
        }
    }
}