import React, { Component } from 'react';
import CustomCalendar from './custom-calendar/customCalendar.component';
import SideCalendar from './side-calendar/sideCalendar.component';
import { withTranslation } from 'react-i18next';
import styles from './styles/calendarView.module.scss';

class CalendarView extends Component {
    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
        this.state = {
            currentDate: new Date(),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentDate !== this.state.currentDate) {
            if (this.calendarRef.current) {
                const instance = this.calendarRef.current.getInstance();
                if (prevState.currentDate > this.state.currentDate) {
                    instance.prev();
                } else {
                    instance.next();
                }
            }
        }
    }

    handleDateChange = (newDate, view) => {
        let adjustedDate = new Date(newDate);
        if (view === 'day') {
        } else if (view === 'week') {
            adjustedDate.setDate(adjustedDate.getDate() - adjustedDate.getDay());
        } else if (view === 'month' || view === 'agenda') {
            adjustedDate.setDate(1);
        }
        this.setState({ currentDate: adjustedDate });
    }

    handlePrev = (view) => {
        this.setState(prevState => {
            const newDate = new Date(prevState.currentDate);
            if (view === 'month' || view === 'agenda') {
                newDate.setMonth(newDate.getMonth() - 1);
            } else if (view === 'week') {
                newDate.setDate(newDate.getDate() - 7);
            } else if (view === 'day') {
                newDate.setDate(newDate.getDate() - 1);
            }
            return { currentDate: newDate };
        });
    }

    handleNext = (view) => {
        this.setState(prevState => {
            const newDate = new Date(prevState.currentDate);
            if (view === 'month' || view === 'agenda') {
                newDate.setMonth(newDate.getMonth() + 1);
            } else if (view === 'week') {
                newDate.setDate(newDate.getDate() + 7);
            } else if (view === 'day') {
                newDate.setDate(newDate.getDate() + 1);
            }
            return { currentDate: newDate };
        });
    }

    render() {
        const { currentDate } = this.state;

        return (
            <div className={styles.calendarApp}>
                <SideCalendar
                    currentDate={currentDate}
                    onPrev={this.handlePrev}
                    onNext={this.handleNext}
                    onDateChange={this.handleDateChange}
                />
                <CustomCalendar
                    calendarRef={this.calendarRef}
                    currentDate={currentDate}
                    onPrev={this.handlePrev}
                    onNext={this.handleNext}
                />
            </div>
        );
    }
}

export default withTranslation()(CalendarView);
