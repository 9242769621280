import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedProfileView: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSelectedProfileView(state, action) {
      state.selectedProfileView = action.payload;
    },
    clearSelectedProfileView(state) {
      state.selectedProfileView = null;
    },
  },
});

export const { setSelectedProfileView, clearSelectedProfileView } = profileSlice.actions;
export default profileSlice.reducer;
