import React, { Component } from "react";
import styles from './styles/dateTimeInput.module.scss';
import { Icon } from '@iconify/react';

class DateTimeInput extends Component {
  constructor(props) {
    super(props);
    const initialValue = this.props.value ? new Date(this.props.value).toISOString().substring(0, 16) : '';
    this.state = {
      data: initialValue,
      displayValue: this.formatDate(this.props.value || ''),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const newValue = this.props.value ? new Date(this.props.value).toISOString().substring(0, 16) : '';
      this.setState({
        data: newValue,
        displayValue: this.formatDate(this.props.value || ''),
      });
    }
  }

  handleInputChange = (event) => {
    const { value } = event.target;
    this.setState({
      data: value,
      displayValue: this.formatDate(value),
    });
    this.props.onChange(value);
  };

  clearInput = (event) => {
    event.stopPropagation();
    this.setState({
      data: '',
      displayValue: '',
    });
    this.props.onChange('');
  };

  formatDate = (value) => {
    if (!value) return '';
    const date = new Date(value);
    const days = ['dom.', 'lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'];
    const months = [
      'ene.', 'feb.', 'mar.', 'abr.', 'may.', 'jun.',
      'jul.', 'ago.', 'sep.', 'oct.', 'nov.', 'dic.'
    ];

    const dayName = days[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${dayName}, ${day} de ${month} de ${year} | ${hours}:${minutes}`;
  };

  handleDisplayClick = () => {
    this.hiddenInput.showPicker();
  };

  render() {
    const { label, customClass } = this.props;
    return (
      <div className={`${styles.dateTimeFieldContainer} ${customClass}`}>
        <div className={styles.dateTime}>
          <label className={styles.labelText}>{label}</label>
          <div className={styles.dateTimeField} onClick={this.handleDisplayClick}>
            <input
              type="text"
              readOnly
              value={this.state.displayValue}
              className={styles.displayInput}
            />
            <input
              type="datetime-local"
              name="data"
              value={this.state.data}
              onChange={this.handleInputChange}
              ref={(input) => this.hiddenInput = input}
              className={styles.hiddenInput}
            />
          </div>
        </div>
        <Icon icon="iconamoon:close" width="20px" height="20px" onClick={this.clearInput} className={styles.clearButton}></Icon>
      </div>
    );
  }
}

export default DateTimeInput;
