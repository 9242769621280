import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],
    offset: 0,
    hasMore: true,
    lastNotification: null,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotificationToStart(state, action) {
      state.notifications.unshift(action.payload);
    },
    removeNotification(state, action) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== action.payload
      );
    },
    clearNotifications(state) {
      state.notifications = [];
      state.offset = 0;
      state.hasMore = true;
    },
    markNotificationAsRead(state, action) {
      const notification = state.notifications.find(
        notification => notification.id === action.payload
      );
      if (notification) {
        notification.readed = true;
      }
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    addNotifications(state, action) {
      const newNotifications = action.payload.filter(newNotification =>
        !state.notifications.some(existingNotification => existingNotification.id === newNotification.id)
      );
      
      state.notifications = [...state.notifications, ...newNotifications];
    },
    
    incrementOffset(state, action) {
      state.offset += action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    setLastNotification(state, action) {
      state.lastNotification = action.payload;
    },
    clearLastNotification(state) {
      state.lastNotification = null;
    },
  },
});

export const {
  addNotificationToStart,
  removeNotification,
  clearNotifications,
  markNotificationAsRead,
  setNotifications,
  addNotifications,
  incrementOffset,
  setHasMore,
  setLastNotification,
  clearLastNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
