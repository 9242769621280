import React, { Component } from "react";
import './assets/styles/recoveryPassword.scss';
import { withTranslation } from 'react-i18next';
import { validEmail } from "../../utils/validation.util";
import { sendEmail } from '../../services/PasswordService';
import Header from "../../components/header/header.component";
import SimpleFooter from "../../components/simple-footer/simpleFooter.component";

class RecoveryPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: null,
      emailSent: false,
    };
  }

  handleBlur = (e) => {
    const { t } = this.props;
    const { value } = e.target;
    if (value !== '' && !validEmail(value)) {
      this.setState({
        emailError: t('PASSWORD_RECOVERY.EMAIL_SEND.ERROR.Email'),
      });
    } else {
      this.setState({ emailError: null });
    }
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      email: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (validEmail(email)) {
      await this.sendEmail(email);
    }
  };

  sendEmail = async (email) => {
    const { t } = this.props;
    try {
      //TODO: Pendiente obtencion tenant
      let tenant = 'aymadrid';
      const response = await sendEmail(email, tenant);
      if (response.status === 500) {
        this.setState({ emailError: t('ERROR.500'), emailSent: false });
      } else if (response.status === 404) {
        console.error('Usuario no existe en el sistema');
        this.setState({ emailError: t('PASSWORD_RECOVERY.EMAIL_SEND.ERROR.UserNotFound'), emailSent: false });
      } else {
        this.setState({ emailSent: true });
      }
    } catch (error) {
      console.error('Error enviando email:', error);
      this.setState({ emailError: t('ERROR.500')});
    }
  };

  render() {
    const { email, emailError, emailSent } = this.state;
    const { t } = this.props;
    return (
      <>
        <Header type='simple' />
        {emailSent ? (
          <section className="recovery-password-confirmation-message">
            <h1 className="title">{t('PASSWORD_RECOVERY.EMAIL_SENT.LABEL.Title')}</h1>
            <p className="description">
              {t('PASSWORD_RECOVERY.EMAIL_SENT.LABEL.DescriptionI')}
              <br />
              {t('PASSWORD_RECOVERY.EMAIL_SENT.LABEL.DescriptionII')}<span className="recovery-password-email">{email}</span>{t('PASSWORD_RECOVERY.EMAIL_SENT.LABEL.DescriptionIII')}
            </p>
          </section>) : (
          <div className="recovery-password-content">
            <div className="recovery-password-title">
              <h1 className="title">{t('PASSWORD_RECOVERY.EMAIL_SEND.LABEL.Title')}</h1>
              <p className="description">{t('PASSWORD_RECOVERY.EMAIL_SEND.LABEL.Description')}</p>
            </div>
            <form className="recovery-password-form" onSubmit={this.handleSubmit}>
              <label htmlFor="email" className="label">{t('PASSWORD_RECOVERY.EMAIL_SEND.LABEL.Email')}</label>
              <input
                type="text"
                id="email"
                className={`input ${emailError ? 'error' : ''}`}
                placeholder={t('PASSWORD_RECOVERY.EMAIL_SEND.LABEL.Email')}
                aria-label={t('PASSWORD_RECOVERY.EMAIL_SEND.LABEL.Email')}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
              />
              {emailError && <p className="error-message">{emailError}</p>}
              <button type="submit" className="submit-button">{t('PASSWORD_RECOVERY.EMAIL_SEND.BUTTON.Send')}</button>
            </form>
          </div>
        )}
        <SimpleFooter />
      </>
    );
  }
}

export default withTranslation()(RecoveryPassword);
