import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import { deleteContent } from '../../../services/ContentService';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import { Icon } from '@iconify/react';
import styles from './styles/deleteContent.module.scss';

class DeleteContent extends Component {
  state = {
    isLoading: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
  };

  handleDelete = async () => {
    this.setState({ isLoading: true });

    try {
      await deleteContent(this.props.contentId);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'ok',
        feedbackMessage: this.props.t("CONTENT.DELETE.SuccessMessage"),
      });
    } catch (error) {
      console.error('Error eliminando el contenido', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("CONTENT.DELETE.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    this.props.onClose(this.state.feedbackType === 'ok');
  };

  render() {
    const { t, onClose } = this.props;
    const { isLoading, isFeedbackModalOpen, feedbackType, feedbackMessage } = this.state;

    const buttons = [
      { label: t("CONTENT.DELETE.DeleteButton"), className: styles.deleteButton, onClick: this.handleDelete },
      { label: t("CONTENT.DELETE.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
    ];

    const icon = <Icon icon="bxs:error" className={styles.icon} />;

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal title={t("CONTENT.DELETE.Title")} titleClassName={styles.modalTitle} buttons={buttons} image={icon} onClose={() => onClose(false)}>
          <div className={styles.messages}>
            <p>{t("CONTENT.DELETE.Message1")}</p>
            <p>{t("CONTENT.DELETE.Message2")}</p>
          </div>
        </Modal>
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(DeleteContent);
