import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import { getCategories, getSubcategories, createContent } from '../../../services/ContentService';
import { searchHomes, getUsersByHomes } from '../../../services/HomeService';
import { searchUsers } from '../../../services/UserService';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import DateTimeInput from '../../date-time-input/dateTimeInput.component';
import CustomInput from '../../custom-input/customInput.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import styles from './styles/createContent.module.scss';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';

class CreateContent extends Component {
  state = {
    contentData: {
      resource: '',
      name: '',
      description: '',
      startDate: '',
      endDate: ''
    },
    categories: [],
    subcategories: [],
    homes: [],
    users: [],
    creationUsers: [],
    selectedCategory: null,
    selectedSubcategory: null,
    selectedHomes: [],
    selectedUsers: [],
    selectedCreationUser: null,
    selectedStatus: { key: true, value: this.props.t("CONTENT.CREATE.Activated") },
    imageSrc: greyImage,
    isUploadModalOpen: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    selectedFile: null,
    errors: {},
    validate: false,
    isLoading: false,
  };

  async componentDidMount() {
    try {
      const categories = await getCategories();
      this.setState({ categories: categories.map(cat => ({ key: cat.id, value: cat.name })) });

      const subcategories = await getSubcategories();
      this.setState({ subcategories: subcategories.map(sub => ({ key: sub.id, value: sub.name })) });

      const homes = await this.searchHomes("", true);
      this.setState({ homes });

      const creationUsers = await this.searchUsers("", true);
      this.setState({ creationUsers });

    } catch (error) {
      console.error('Error obteniendo categories, subcategories, homes, o users:', error);
    }
  }

  handleSave = async () => {
    const { contentData } = this.state;
    const errors = {};

    if (!contentData.name) errors.name = "Este campo es obligatorio";
  
    this.setState({ errors, validate: true });

    if (Object.keys(errors).length > 0) {
      return;
    }

    const {
      selectedCategory,
      selectedSubcategory,
      selectedUsers,
      selectedCreationUser,
      selectedStatus,
      selectedFile
    } = this.state;

    const data = {
      file: selectedFile,
      resource: contentData.resource,
      name: contentData.name,
      description: contentData.description,
      status: selectedStatus.key,
      categoryId: selectedCategory?.[0]?.key || null,
      subcategoryId: selectedSubcategory?.[0]?.key || null,
      creationUserId: selectedCreationUser?.key || null,
      userIds: selectedUsers.map(user => user.key),
      startDate: contentData.startDate,
      endDate: contentData.endDate
    };

    this.setState({ isLoading: true });

    try {
      await createContent(data);
      this.props.onClose(true);
    } catch (error) {
      console.error('Error creando content:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("CONTENT.CREATE.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  setSelectedCategory = (selectedOptions) => {
    this.setState({ selectedCategory: Array.isArray(selectedOptions) ? selectedOptions : [] });
  }

  setSelectedSubcategory = (selectedOptions) => {
    this.setState({ selectedSubcategory: Array.isArray(selectedOptions) ? selectedOptions : [] });
  }

  setSelectedHomes = async (selectedOptions) => {
    this.setState({ selectedHomes: Array.isArray(selectedOptions) ? selectedOptions : [] });
    await this.updateUsers(selectedOptions.map(home => home.key));
  }

  setSelectedUsers = (selectedOptions) => {
    this.setState({ selectedUsers: Array.isArray(selectedOptions) ? selectedOptions : [] });
  }

  setSelectedCreationUser = (selectedOptions) => {
    this.setState({ selectedCreationUser: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedStatus = (selectedOptions) => {
    this.setState({ selectedStatus: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  searchHomes = async (term) => {
    try {
      const homes = await searchHomes(term, true);
      return homes.map(home => ({ key: home.id, value: home.name }));
    } catch (error) {
      console.error('Error buscando homes:', error);
      return [];
    }
  }

  updateUsers = async (homeIds) => {
    try {
      const users = await getUsersByHomes(homeIds);
      const activeUsers = users.filter(user => user.active);
      const userOptions = activeUsers.map(user => ({ key: user.id, value: user.email }));
      this.setState({ users: userOptions, selectedUsers: userOptions });
    } catch (error) {
      console.error('Error obteniendo users:', error);
    }
  }

  searchUsers = async (term) => {
    try {
      const withActive = true;
      const users = await searchUsers(term, withActive);
      return users.map(user => ({ key: user.id, value: user.email }));
    } catch (error) {
      console.error('Error buscando users:', error);
      return [];
    }
  }

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  }

  handleDateChange = (dateType, dateValue) => {
    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [dateType]: dateValue
      }
    }));
  }

  handleImageMouseEnter = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  handleEditClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file)});
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    if (this.state.feedbackType === 'ok') {
      this.props.onClose(true);
    }
  };

  render() {
    const { categories, subcategories, homes, users, creationUsers, selectedUsers, selectedCreationUser, selectedStatus, contentData, imageSrc, isUploadModalOpen, isFeedbackModalOpen, feedbackType, feedbackMessage, validate, isLoading } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("CONTENT.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    const statusOptions = [
      { key: true, value: t("CONTENT.CREATE.Activated") },
      { key: false, value: t("CONTENT.CREATE.Deactivated") }
    ];

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal title={t("CONTENT.CREATE.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formContent}>
            <div className={styles.row}>
              <div className={styles.imageFormHeader}>
                <label className={styles.label}>{t("CONTENT.CREATE.Image")}</label>
                <span className={styles.editLabel} onClick={this.handleEditClick}>{t("CONTENT.CREATE.editImage")}</span>
              </div>
              <img
                src={imageSrc}
                alt=""
                className={styles.image}
                onMouseEnter={this.handleImageMouseEnter}
                onMouseLeave={this.handleImageMouseLeave}
                onClick={this.handleImageClick}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Resource")}</label>
              <CustomInput
                type="text"
                name="resource"
                value={contentData.resource}
                onChange={(value) => this.handleInputChange('resource', value)}
                placeholder={t("CONTENT.CREATE.ResourcePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={contentData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("CONTENT.CREATE.NamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("CONTENT.CREATE.ValidationRequired") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Description")}</label>
              <CustomInput
                type="text"
                name="description"
                value={contentData.description}
                onChange={(value) => this.handleInputChange('description', value)}
                placeholder={t("CONTENT.CREATE.DescriptionPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.CreationUser")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={creationUsers}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCreationUserPlaceholder")}
                setSelectedOptions={this.setSelectedCreationUser}
                searchable={true}
                search={this.searchUsers}
                selectedOptions={selectedCreationUser ? [selectedCreationUser] : []}
                customClass={styles.selectCreationUser}
                required={true}
                validate={validate}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Category")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={categories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCategoryPlaceholder")}
                setSelectedOptions={this.setSelectedCategory}
                customClass={styles.selectCategory}
                required={true}
                validate={validate}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Subcategory")}</label>
              <DropdownSelect
                options={subcategories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectSubcategoryPlaceholder")}
                setSelectedOptions={this.setSelectedSubcategory}
                customClass={styles.selectSubcategory}
              />
            </div>
            <div className={`${styles.row} ${styles.spaceBetween}`}>
              <label className={styles.label}>{t("CONTENT.CREATE.Status")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={statusOptions}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectStatusPlaceholder")}
                setSelectedOptions={this.setSelectedStatus}
                selectedOptions={[selectedStatus]}
                customClass={styles.selectStatus}
                required={true}
                validate={validate}
              />
              <DropdownSelect
                options={homes}
                isMultiple={true}
                placeholder={t("CONTENT.CREATE.SelectHomePlaceholder")}
                setSelectedOptions={this.setSelectedHomes}
                searchable={true}
                search={this.searchHomes}
                customClass={styles.selectHome}
                required={true}
                validate={validate}
              />
              <DropdownSelect
                options={users}
                isMultiple={true}
                placeholder={t("CONTENT.CREATE.SelectUsersPlaceholder")}
                setSelectedOptions={this.setSelectedUsers}
                searchable={true}
                selectedOptions={selectedUsers}
                customClass={styles.selectUser}
                disabled={users.length === 0}
                required={true}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Date")}</label>
              <DateTimeInput
                label={t("CONTENT.CREATE.StartDate")}
                customClass={styles.dateClass}
                value={contentData.startDate}
                onChange={(date) => this.handleDateChange('startDate', date)}
              />
              <DateTimeInput
                label={t("CONTENT.CREATE.EndDate")}
                customClass={styles.dateClass}
                value={contentData.endDate}
                onChange={(date) => this.handleDateChange('endDate', date)}
              />
            </div>
          </div>
        </Modal>
        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(CreateContent);
