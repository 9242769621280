import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import { createHome } from '../../../services/HomeService';
import CustomInput from '../../custom-input/customInput.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import styles from './styles/createHome.module.scss';

class CreateHome extends Component {
  state = {
    homeData: {
      name: '',
      address: '',
      province: '',
      locality: '',
      telephone: '',
      postalCode: '',
      niu: '',
      active: true
    },
    errors: {},
    validate: false,
    isLoading: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
  };

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      homeData: {
        ...prevState.homeData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  };

  validateFields = () => {
    const { homeData } = this.state;
    const errors = {};

    if (!homeData.name) errors.name = this.props.t("HOMES.LABEL.ErrorFormField");
    if (!homeData.niu) errors.niu = this.props.t("HOMES.LABEL.ErrorFormField");
    if (!homeData.address) errors.address = this.props.t("HOMES.LABEL.ErrorFormField");
    if (!homeData.province) errors.province = this.props.t("HOMES.LABEL.ErrorFormField");
    if (!homeData.locality) errors.locality = this.props.t("HOMES.LABEL.ErrorFormField");
    if (!homeData.postalCode) errors.postalCode = this.props.t("HOMES.LABEL.ErrorFormField");

    this.setState({ errors, validate: true });
    return errors;
  };

  handleSave = async () => {
    const errors = this.validateFields();
    if (Object.keys(errors).length > 0) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      await createHome(this.state.homeData);
      this.props.onClose(true);
    } catch (error) {
      console.error('Error creando el hogar', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("HOMES.CREATE.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    this.props.onClose(this.state.feedbackType === 'ok');
  };

  render() {
    const { homeData, validate, isLoading, isFeedbackModalOpen, feedbackType, feedbackMessage } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("HOMES.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("HOMES.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    return (
      <>
        <Modal title={t("HOMES.CREATE.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formContent}>
            <div className={styles.row}>
              <label>{t("HOMES.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={homeData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("HOMES.CREATE.FullNamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("HOMES.LABEL.ErrorFormField") }]}
                validate={validate}
              />
              <label>{t("HOMES.CREATE.NIU")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="niu"
                value={homeData.niu}
                onChange={(value) => this.handleInputChange('niu', value)}
                placeholder={t("HOMES.CREATE.NiuPlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("HOMES.LABEL.ErrorFormField") }]}
                validate={validate}
              />
              <div className={styles.switchGroup}>
                <label className={styles.switchLabel}>
                  {homeData.active ? t("HOMES.CREATE.Activated") : t("HOMES.CREATE.Deactivated")}
                </label>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    name="active"
                    checked={homeData.active}
                    onChange={(e) => this.handleInputChange('active', e.target.checked)}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
              </div>
            </div>

            <div className={styles.row}>
              <label>{t("HOMES.CREATE.Address")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="address"
                value={homeData.address}
                onChange={(value) => this.handleInputChange('address', value)}
                placeholder={t("HOMES.CREATE.AddressPlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("HOMES.LABEL.ErrorFormField") }]}
                validate={validate}
              />

              <label>{t("HOMES.CREATE.Locality")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="locality"
                value={homeData.locality}
                onChange={(value) => this.handleInputChange('locality', value)}
                placeholder={t("HOMES.CREATE.LocalityPlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("HOMES.LABEL.ErrorFormField") }]}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label>{t("HOMES.CREATE.Province")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="province"
                value={homeData.province}
                onChange={(value) => this.handleInputChange('province', value)}
                placeholder={t("HOMES.CREATE.ProvincePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("HOMES.LABEL.ErrorFormField") }]}
                validate={validate}
              />
              <label>{t("HOMES.CREATE.PostalCode")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="postalCode"
                value={homeData.postalCode}
                onChange={(value) => this.handleInputChange('postalCode', value)}
                placeholder={t("HOMES.CREATE.PostalCodePlaceholder")}
                customClass={styles.inputCp}
                validators={[{ regex: /.+/, message: t("HOMES.LABEL.ErrorFormField") }]}
                validate={validate}
              />
              <label>{t("HOMES.CREATE.Phone")}</label>
              <CustomInput
                type="text"
                name="telephone"
                value={homeData.telephone}
                onChange={(value) => this.handleInputChange('telephone', value)}
                placeholder={t("HOMES.CREATE.PhonePlaceholder")}
                customClass={styles.inputField}
              />
            </div>
          </div>
        </Modal>
        {isLoading && <LoadingScreen />}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(CreateHome);
