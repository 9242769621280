import { store } from '../redux/store';

const getUserRolesFromState = () => {
    const state = store.getState();
    const { userRoles } = state.user || {};
    const { tenant } = state.tenant || {};
    
    if (!userRoles || !tenant) {
        return '';
    }
    return userRoles[tenant] || '';
};

const hasUserRole = () => {
    const role = getUserRolesFromState();
    return role === 'user';
};

const hasAdminRole = () => {
    const role = getUserRolesFromState();
    return role === 'admin';
};

const hasProfesionalRole = () => {
    const role = getUserRolesFromState();
    return role === 'professional';
};

export { hasUserRole, hasAdminRole, hasProfesionalRole };
