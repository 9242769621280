import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import { getCategories, getSubcategories, updateContent } from '../../../services/ContentService';
import { searchHomes, getUsersByHomes } from '../../../services/HomeService';
import { searchUsers, getHomesByUsers } from '../../../services/UserService';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import DateTimeInput from '../../date-time-input/dateTimeInput.component';
import CustomInput from '../../custom-input/customInput.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import styles from './styles/updateContent.module.scss';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';
import { getImage } from '../../../services/BlobService';
import { Icon } from '@iconify/react';
import CustomModal from '../../custom-modal/customModal.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';

class UpdateContent extends Component {
  state = {
    contentData: {
      id: '',
      resource: '',
      name: '',
      description: '',
      startDate: '',
      endDate: ''
    },
    categories: [],
    subcategories: [],
    homes: [],
    users: [],
    creationUsers: [],
    selectedCategory: null,
    selectedSubcategory: null,
    selectedHomes: [],
    selectedUsers: [],
    selectedCreationUser: null,
    selectedStatus: { key: true, value: this.props.t("CONTENT.CREATE.Activated") },
    imageSrc: greyImage,
    isUploadModalOpen: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    selectedFile: null,
    errors: {},
    validate: false,
    isLoading: true,
    isUpdating: false,
    showUpdateConfirmationModal: false,
  };

  async componentDidMount() {
    try {
      this.setState({ isUpdating: true });
      const categories = await getCategories();
      this.setState({ categories: categories.map(cat => ({ key: cat.id, value: cat.name })) });

      const subcategories = await getSubcategories();
      this.setState({ subcategories: subcategories.map(sub => ({ key: sub.id, value: sub.name })) });

      const content = this.props.content;

      const selectedHomes = await getHomesByUsers(content.userIds);
      const homeIds = selectedHomes.map(home => home.id);

      const users = await getUsersByHomes(homeIds);
      const selectedUsers = users.filter(user => content.userIds.includes(user.id));

      const selectedCategory = content.category || null;
      const selectedSubcategory = content.subcategory || null;
      const selectedCreationUser = content.creator;

      const activeHomes = await this.searchHomes("");
      const filteredActiveHomes = activeHomes.filter(home => home.active);
      const allHomesMap = new Map(filteredActiveHomes.map(home => [home.id, home]));
      selectedHomes.forEach(home => allHomesMap.set(home.id, home));
      const allHomes = Array.from(allHomesMap.values());

      const creationUsers = await this.searchUsers("", true);
      if (content.creator && !creationUsers.find(user => user.key === content.creator.id)) {
        creationUsers.push({ key: content.creator.id, value: content.creator.email });
      }

      const activeUsers = users.filter(user => user.active);
      const allUsersMap = new Map(activeUsers.map(user => [user.id, user]));
      selectedUsers.forEach(user => allUsersMap.set(user.id, user));
      if (content.creator) {
        allUsersMap.set(content.creator.id, content.creator);
      }

      const allUsers = Array.from(allUsersMap.values());

      this.setState({
        contentData: {
          id: content.id,
          resource: content.resource,
          name: content.name,
          description: content.description,
          startDate: content.startDate,
          endDate: content.endDate
        },
        selectedCategory: selectedCategory ? { key: selectedCategory.id, value: selectedCategory.name } : null,
        selectedSubcategory: selectedSubcategory ? { key: selectedSubcategory.id, value: selectedSubcategory.name } : null,
        selectedCreationUser: selectedCreationUser ? { key: selectedCreationUser.id, value: selectedCreationUser.email } : null,
        selectedStatus: content.active ? { key: true, value: this.props.t("CONTENT.CREATE.Activated") } : { key: false, value: this.props.t("CONTENT.CREATE.Deactivated") },
        selectedFile: content.image || null,
        selectedHomes: selectedHomes.map(home => ({ key: home.id, value: home.name })),
        selectedUsers: selectedUsers.map(user => ({ key: user.id, value: user.email })),
        homes: allHomes.map(home => ({ key: home.id, value: home.name })),
        users: allUsers.map(user => ({ key: user.id, value: user.email })),
        creationUsers: creationUsers
      }, () => {
        if (this.state.selectedFile) {
          this.fetchImage(this.state.selectedFile);
        } else {
          this.setState({ isLoading: false });
        }
      });
      this.setState({ isUpdating: false });
    } catch (error) {
      console.error('Error obteniendo categories, subcategories, homes, o users:', error);
      this.setState({ isUpdating: false });
      this.setState({ isLoading: false });
    }
  }

  fetchImage = async (imageId) => {
    if (imageId) {
      this.setState({ isLoading: true });
      try {
        const url = await getImage(imageId);
        this.setState({ imageSrc: url, isLoading: false });
      } catch (error) {
        console.error('Error fetching image:', error);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSave = () => {
    const errors = this.validateFields();
    if (Object.keys(errors).length > 0) {
      return;
    }
    this.setState({ showUpdateConfirmationModal: true });
  };
  handleConfirmUpdate = async () => {
    this.setState({ showUpdateConfirmationModal: false, isUpdating: true });
    try {
      const {
        selectedCategory,
        selectedSubcategory,
        selectedUsers,
        selectedCreationUser,
        selectedStatus,
        selectedFile,
        contentData
      } = this.state;
  
      const data = {
        id: this.props.content.id,
        file: selectedFile,
        resource: contentData.resource,
        name: contentData.name,
        description: contentData.description,
        status: selectedStatus.key ? true : false,
        categoryId: selectedCategory?.key || null,
        subcategoryId: selectedSubcategory?.key || null,
        creationUserId: selectedCreationUser?.key || null,
        userIds: selectedUsers.map(user => user.key),
        startDate: contentData.startDate,
        endDate: contentData.endDate
      };
      await updateContent(data);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'ok',
        feedbackMessage: this.props.t("CONTENT.EDIT.SuccessMessage"),
      });
    } catch (error) {
      console.error('Error editando content:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("CONTENT.EDIT.ErrorMessage"),
      });
    } finally {
      this.setState({ isUpdating: false });
    }
  };

  handleCancelUpdate = () => {
    this.setState({ showUpdateConfirmationModal: false });
  };

  validateFields = () => {
    const { contentData } = this.state;
    const errors = {};

    if (!contentData.name) errors.name = this.props.t("CONTENT.LABEL.ErrorFormField");

    this.setState({ errors, validate: true });
    return errors;
  };

  setSelectedCategory = (selectedOptions) => {
    this.setState({ selectedCategory: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedSubcategory = (selectedOptions) => {
    this.setState({ selectedSubcategory: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedHomes = async (selectedOptions) => {
    this.setState({ selectedHomes: Array.isArray(selectedOptions) ? selectedOptions : [] });
    await this.updateUsers(selectedOptions.map(home => home.key));
  }

  setSelectedUsers = (selectedOptions) => {
    this.setState({ selectedUsers: Array.isArray(selectedOptions) ? selectedOptions : [] });
  }

  setSelectedCreationUser = (selectedOptions) => {
    this.setState({ selectedCreationUser: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedStatus = (selectedOptions) => {
    this.setState({ selectedStatus: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  searchHomes = async (term) => {
    try {
      const homes = await searchHomes(term);
      return homes.map(home => ({ key: home.id, value: home.name }));
    } catch (error) {
      console.error('Error buscando homes:', error);
      return [];
    }
  }

  updateUsers = async (homeIds) => {
    try {
      const users = await getUsersByHomes(homeIds);
      const selectedUserIds = this.state.selectedUsers.map(user => user.key);
      const userOptions = users.filter(user => user.active || selectedUserIds.includes(user.id)).map(user => ({ key: user.id, value: user.email }));
      this.setState({ users: userOptions });
    } catch (error) {
      console.error('Error obteniendo users:', error);
    }
  }

  searchUsers = async (term) => {
    try {
      const withActive = true;
      const users = await searchUsers(term, withActive);
      return users.map(user => ({ key: user.id, value: user.email }));
    } catch (error) {
      console.error('Error buscando users:', error);
      return [];
    }
  }

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  }

  handleDateChange = (dateType, dateValue) => {
    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [dateType]: dateValue
      }
    }));
  }

  handleImageMouseEnter = () => {
    if (this.state.selectedFile) {
      return;
    }
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.selectedFile) {
      return;
    }
    this.setState({ imageSrc: greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  handleEditClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file) });
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    if (this.state.feedbackType === 'ok') {
      this.props.onClose(true);
    }
  };

  render() {
    const { showUpdateConfirmationModal, categories, subcategories, homes, users, creationUsers, selectedUsers, selectedCreationUser, selectedStatus, contentData, imageSrc, isUploadModalOpen, selectedCategory, selectedSubcategory, selectedHomes, validate, isLoading, isUpdating, isFeedbackModalOpen, feedbackType, feedbackMessage } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("CONTENT.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    const updateConfirmationButtons = [
      { label: t("CONTENT.CREATE.ConfirmButton"), className: styles.saveButton, onClick: this.handleConfirmUpdate },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: this.handleCancelUpdate }
    ];

    const statusOptions = [
      { key: true, value: t("CONTENT.CREATE.Activated") },
      { key: false, value: t("CONTENT.CREATE.Deactivated") }
    ];

    return (
      <>
        {isUpdating && <LoadingScreen />}
        <Modal title={t("CONTENT.EDIT.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formContent}>
            <div className={styles.row}>
              <div className={styles.imageFormHeader}>
                <label className={styles.label}>{t("CONTENT.CREATE.Image")}</label>
                <span className={styles.editLabel} onClick={this.handleEditClick}>{t("CONTENT.CREATE.editImage")}</span>
              </div>
              <div className={styles.imageContainer}>
                {isLoading ? (
                  <div className={styles.loadingIndicator}>
                    <Icon icon="eos-icons:loading" width="45" height="45" />
                  </div>
                ) : (
                  <img
                    src={imageSrc}
                    alt=""
                    className={styles.image}
                    onMouseEnter={this.handleImageMouseEnter}
                    onMouseLeave={this.handleImageMouseLeave}
                    onClick={this.handleImageClick}
                  />
                )}
              </div>
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Resource")}</label>
              <CustomInput
                type="text"
                name="resource"
                value={contentData.resource}
                onChange={(value) => this.handleInputChange('resource', value)}
                placeholder={t("CONTENT.CREATE.ResourcePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={contentData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("CONTENT.CREATE.NamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("CONTENT.CREATE.ValidationRequired") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Description")}</label>
              <CustomInput
                type="text"
                name="description"
                value={contentData.description}
                onChange={(value) => this.handleInputChange('description', value)}
                placeholder={t("CONTENT.CREATE.DescriptionPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.CreationUser")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={creationUsers}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCreationUserPlaceholder")}
                setSelectedOptions={this.setSelectedCreationUser}
                searchable={true}
                search={this.searchUsers}
                selectedOptions={selectedCreationUser ? [selectedCreationUser] : []}
                customClass={styles.selectCreationUser}
                required={true}
                validate={validate}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Category")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={categories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCategoryPlaceholder")}
                setSelectedOptions={this.setSelectedCategory}
                customClass={styles.selectCategory}
                selectedOptions={selectedCategory ? [selectedCategory] : []}
                required={true}
                validate={validate}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Subcategory")}</label>
              <DropdownSelect
                options={subcategories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectSubcategoryPlaceholder")}
                setSelectedOptions={this.setSelectedSubcategory}
                selectedOptions={selectedSubcategory ? [selectedSubcategory] : []}
                customClass={styles.selectSubcategory}
              />
            </div>
            <div className={`${styles.row} ${styles.spaceBetween}`}>
              <label className={styles.label}>{t("CONTENT.CREATE.Status")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={statusOptions}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectStatusPlaceholder")}
                setSelectedOptions={this.setSelectedStatus}
                selectedOptions={selectedStatus ? [selectedStatus] : []}
                customClass={styles.selectStatus}
                required={true}
                validate={validate}
              />
              <DropdownSelect
                options={homes}
                isMultiple={true}
                placeholder={t("CONTENT.CREATE.SelectHomePlaceholder")}
                setSelectedOptions={this.setSelectedHomes}
                selectedOptions={selectedHomes}
                searchable={true}
                search={this.searchHomes}
                customClass={styles.selectHome}
                required={true}
                validate={validate}
              />
              <DropdownSelect
                options={users}
                isMultiple={true}
                placeholder={t("CONTENT.CREATE.SelectUsersPlaceholder")}
                setSelectedOptions={this.setSelectedUsers}
                searchable={true}
                selectedOptions={selectedUsers}
                customClass={styles.selectUser}
                disabled={users.length === 0}
                required={true}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Date")}</label>
              <DateTimeInput
                label={t("CONTENT.CREATE.StartDate")}
                customClass={styles.dateClass}
                value={contentData.startDate}
                onChange={(date) => this.handleDateChange('startDate', date)}
              />
              <DateTimeInput
                label={t("CONTENT.CREATE.EndDate")}
                customClass={styles.dateClass}
                value={contentData.endDate}
                onChange={(date) => this.handleDateChange('endDate', date)}
              />
            </div>
          </div>
        </Modal>
        {showUpdateConfirmationModal && (
          <CustomModal
            title={t("CONTENT.EDIT.EditConfirmationTitle")}
            customText={t("CONTENT.EDIT.EditConfirmationMessage")}
            icon="mdi:event-edit"
            iconClassName={styles.updateIcon}
            buttons={updateConfirmationButtons}
            onClose={this.handleCancelUpdate}
          />
        )}
        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UpdateContent);
