import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles/dropdownSelect.module.scss';
import { Icon } from '@iconify/react';
import { withTranslation } from 'react-i18next';

class DropdownSelect extends Component {
  dropdownRef = createRef();

  state = {
    isOpen: false,
    searchTerm: '',
    selectedOptions: this.props.selectedOptions || [],
    filteredOptions: this.props.options,
    validate: this.props.validate || false,
    error: this.props.t("SELECT.ValidateError"),
  };

  toggleDropdown = () => {
    if (!this.props.disabled) {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }
  };

  handleOptionClick = (option, event) => {
    const { isMultiple, setSelectedOptions, disabled } = this.props;
    if (disabled) return;

    if (isMultiple) {
      if (event.target.type !== 'checkbox') {
        this.handleCheckboxChange(option);
      }
    } else {
      const newSelectedOptions = [option];
      this.setState({ selectedOptions: newSelectedOptions, isOpen: false });
      setSelectedOptions(newSelectedOptions);
    }
  };

  handleCheckboxChange = (option) => {
    const { setSelectedOptions, disabled } = this.props;
    if (disabled) return;

    this.setState((prevState) => {
      let newSelectedOptions;
      if (prevState.selectedOptions.some((item) => item.key === option.key)) {
        newSelectedOptions = prevState.selectedOptions.filter((item) => item.key !== option.key);
      } else {
        newSelectedOptions = [...prevState.selectedOptions, option];
      }
      setSelectedOptions(newSelectedOptions);
      return { selectedOptions: newSelectedOptions };
    });
  };

  handleSearchChange = async (e) => {
    const { search, options, disabled } = this.props;
    if (disabled) return;

    const value = e.target.value;
    this.setState({ searchTerm: value });
    if (search) {
      try {
        const results = await search(value);
        this.setState({ filteredOptions: results });
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      this.setState({
        filteredOptions: options.filter((option) =>
          option.value && option.value.toLowerCase().includes(value.toLowerCase())
        ),
      });
    }
  };

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOptions !== this.props.selectedOptions) {
      this.setState({ selectedOptions: this.props.selectedOptions || [] });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({ filteredOptions: this.props.options });
    }
    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate });
    }
  }

  render() {
    const { isOpen, searchTerm, selectedOptions, filteredOptions, validate, error } = this.state;
    const { isMultiple, placeholder, t, customClass, searchPlaceholder, searchable, disabled, required, noOptionsText } = this.props;

    return (
      <div className={`${styles.dropdownContainer} ${customClass} ${disabled ? styles.disabled : ''} ${validate && !selectedOptions.length ? styles.err : ''}`} ref={this.dropdownRef}>
        <div
          className={`${styles.dropdownHeader} ${isOpen ? styles.active : ''}`}
          onClick={this.toggleDropdown}
        >
          <div className={styles.placeholder}>
            {isMultiple
              ? (selectedOptions.length > 0
                ? `${selectedOptions.length} ${t('SELECT.ItemsSelected')}`
                : placeholder)
              : (selectedOptions.length > 0 ? selectedOptions.map(option => option.value).join(', ') : placeholder)}
            {required && !selectedOptions.length && <span className={styles.requiredMark}> *</span>}
          </div>
          <div className={styles.iconGroup}>
            {validate && !selectedOptions.length && (
              <div className={styles.icon}>
                <div className={styles.tooltip}>
                  <Icon icon="mingcute:alert-fill" width="24px" height="24px" className={styles.errorIcon} />
                  <span className={styles.tooltiptext}>
                    <div dangerouslySetInnerHTML={{ __html: error }}></div>
                  </span>
                </div>
              </div>
            )}
            <span>{isOpen ? <Icon icon="icon-park-outline:up" width="24px" height="24px" /> : <Icon icon="icon-park-outline:down" width="24px" height="24px" />}</span>
          </div>
        </div>
        {isOpen && (
          <div className={styles.dropdownListContainer}>
            {searchable && (
              <div className={`${styles.searchContainer} ${searchTerm ? styles.active : ''}`}>
                <input
                  className={styles.searchInput}
                  type="text"
                  value={searchTerm}
                  onChange={this.handleSearchChange}
                  placeholder={searchPlaceholder || t('SELECT.SearchPlaceholder')}
                  disabled={disabled}
                />
                <Icon icon="lucide:search" width="20px" height="20px" className={styles.searchIcon} />
              </div>
            )}
            <ul className={styles.dropdownList}>
              {filteredOptions.map((option, index) => (
                <li
                  key={index}
                  className={`${styles.listItem} ${selectedOptions.some(selected => selected.key === option.key) ? styles.listItemSelected : ''}`}
                  onClick={(event) => this.handleOptionClick(option, event)}
                >
                  {isMultiple && (
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={selectedOptions.some(selected => selected.key === option.key)}
                      onChange={() => this.handleCheckboxChange(option)}
                      disabled={disabled}
                    />
                  )}
                  <span>{option.value}</span>
                </li>
              ))}
              {!filteredOptions.length && (
                <li className={styles.noOptions}><span>{noOptionsText}</span></li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  isMultiple: PropTypes.bool,
  placeholder: PropTypes.string,
  setSelectedOptions: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  customClass: PropTypes.string,
  search: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    value: PropTypes.string,
  })),
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  validate: PropTypes.bool,
  noOptionsText: PropTypes.string,
};

DropdownSelect.defaultProps = {
  isMultiple: false,
  placeholder: '',
  searchPlaceholder: '',
  customClass: '',
  search: null,
  selectedOptions: [],
  searchable: false,
  disabled: false,
  required: false,
  validate: false,
  noOptionsText: '',
};

export default withTranslation()(DropdownSelect);
