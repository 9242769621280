import React, { Component } from "react";
import ContentTable from "./content-table/contentTable.component";
import { withTranslation } from 'react-i18next';
import CreateContent from "./content-create/createContent.component";
import UpdateContent from "./content-update/updateContent.component";
import DeleteContent from "./content-delete/deleteContent.component";
import ModalConfirmation from '../modal-confirmation/modalConfirmation.component';
import styles from "./styles/contentView.module.scss";
import { Icon } from '@iconify/react';
import { searchContent } from '../../services/ContentService';
import { connect } from 'react-redux';
import { hasAdminRole } from '../../utils/credentials.util';

const mapStateToProps = (state) => ({
    userRoles: state.user.userRoles || []
});

class ContentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            contentData: [],
            loading: false,
            showCreateContent: false,
            showUpdateContent: false,
            showDeleteContent: false,
            showCreateConfirmationModal: false,
            contentToUpdate: null,
            contentToDelete: null,
            scrollToTop: false,
        };
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value }, this.fetchData);
    };

    handleStatusChange = async (id, newStatus) => {
        try {
            this.setState(prevState => ({
                contentData: prevState.contentData.map(content =>
                    content.id === id ? { ...content, active: newStatus } : content
                )
            }));
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };

    fetchData = async () => {
        this.setState({ loading: true, error: null });
        const { searchQuery } = this.state;

        try {
            const data = await searchContent(searchQuery);
            this.setState({ contentData: data, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    handleShowCreateContent = () => {
        this.setState({ showCreateContent: true });
    };

    handleCloseCreateContent = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
            this.setState({ showCreateConfirmationModal: true });
        }
        this.setState({ showCreateContent: false });
    };

    handleCloseCreateConfirmationModal = () => {
        this.setState({ showCreateConfirmationModal: false });
    };

    handleShowUpdateContent = async (content) => {
        try {
            this.setState({ contentToUpdate: content, showUpdateContent: true });
        } catch (error) {
            console.error('Error fetching content for update:', error);
        }
    };

    handleCloseUpdateContent = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
        }
        this.setState({ showUpdateContent: false, contentToUpdate: null });
    };

    handleShowDeleteContent = (id) => {
        this.setState({ contentToDelete: id, showDeleteContent: true });
    };

    handleCloseDeleteContent = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
        }
        this.setState({ showDeleteContent: false, contentToDelete: null });
    };

    makeScroll = () => {
        this.setState({ scrollToTop: true });
    };

    resetScroll = () => {
        this.setState({ scrollToTop: false });
    };

    render() {
        const { t } = this.props;
        const { searchQuery, contentData, loading, showCreateContent, showUpdateContent, contentToUpdate, showDeleteContent, contentToDelete, scrollToTop, showCreateConfirmationModal } = this.state;

        return (
            <div className={styles.container}>
                <h1>{t('CONTENT.LABEL.Title')}</h1>
                <div className={styles.searchAndButton}>
                    <div className={styles.searchContainer}>
                        <Icon icon="lucide:search" width="18px" height="18px" className={styles.searchIcon} />
                        <input
                            type="text"
                            placeholder={t('CONTENT.LABEL.SearchBar')}
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                            className={styles.searchBar}
                        />
                    </div>
                    {hasAdminRole() && (
                        <button className={styles.addContentButton} onClick={this.handleShowCreateContent}>
                            {t('CONTENT.LABEL.AddContent')}
                        </button>
                    )}
                </div>
                {!loading && (
                    <ContentTable
                        contentData={contentData}
                        onStatusChange={this.handleStatusChange}
                        onEdit={(content) => this.handleShowUpdateContent(content)}
                        onDelete={(id) => this.handleShowDeleteContent(id)}
                        onScrollToTop={this.resetScroll}
                        scrollToTop={scrollToTop}
                    />
                )}
                {showCreateContent && <CreateContent onClose={this.handleCloseCreateContent} />}
                {showUpdateContent && <UpdateContent content={contentToUpdate} onClose={(toFetch) => this.handleCloseUpdateContent(toFetch)} />}
                {showDeleteContent && <DeleteContent contentId={contentToDelete} onClose={this.handleCloseDeleteContent} />}

                {showCreateConfirmationModal && (
                    <ModalConfirmation
                        title={t("CONTENT.CREATE.SuccessTitle")}
                        customText={t("CONTENT.CREATE.SuccessMessage")}
                        icon="mdi:event-add"
                        onClose={this.handleCloseCreateConfirmationModal}
                    />
                )}
                <div className={styles.scrollToTopButton} onClick={this.makeScroll}>
                    <Icon icon="icon-park-outline:up" width="30px" height="30px" />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(ContentView));
