import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Header from "../../components/header/header.component";
import SimpleFooter from "../../components/simple-footer/simpleFooter.component";
import succesIcon from './assets/img/success.svg';
import { updatePassword } from '../../services/PasswordService';
import { checkToken } from '../../services/auth/AuthService';
import './assets/style/modifyPassword.scss';

class ModifyPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            replyPassword: '',
            passwordError: '',
            replyPasswordError: '',
            passwordUpdated: false,
            token: null
        };
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        if (!checkToken(token)) {
            console.error('Token is required');
            this.props.history.push('/login');
        }
        this.setState({ token });
    }

    handleBlur = (field) => {
        const { t } = this.props;
        const { password, replyPassword } = this.state;

        if (field === 'password') {
            if (password === '') {
                this.setState({ passwordError: t('PASSWORD_MODIFY.MODIFY.ERROR.PasswordEmpty') });
            } else {
                this.setState({ passwordError: '' });
            }
        } else if (field === 'replyPassword') {
            if (replyPassword === '') {
                this.setState({ replyPasswordError: t('PASSWORD_MODIFY.MODIFY.ERROR.PasswordEmpty') });
            } else if (replyPassword !== password) {
                this.setState({ replyPasswordError: t('PASSWORD_MODIFY.MODIFY.ERROR.PasswordsNotMatch') });
            } else {
                this.setState({ replyPasswordError: '' });
            }
        }
    };

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    handleSubmit = (e) => {
        const { t } = this.props;
        e.preventDefault();
        const { password, replyPassword } = this.state;

        if (password === '') {
            this.setState({ passwordError: t('PASSWORD_MODIFY.MODIFY.ERROR.PasswordEmpty') });
        }
        if (replyPassword === '') {
            this.setState({ replyPasswordError: t('PASSWORD_MODIFY.MODIFY.ERROR.PasswordEmpty') });
        }
        if (password !== '' && replyPassword !== '' && password === replyPassword) {
            console.log('Contraseñas válidas. Enviar formulario.');
            this.update(password);
        }
    };

    handleLoginClick = () => {
        this.props.history.push('/login');
    }

    update = async (password) => {
        const { t } = this.props;
        const { token } = this.state;
        try {
            const response = await updatePassword(password, token);
            if (response.status === 500) {
                this.setState({ passwordError: t('ERROR.500'), passwordUpdated: false });
            } else {
                this.setState({ passwordUpdated: true });
            }
        } catch (error) {
            console.error('Error actualizando contraseña:', error);
            this.setState({ passwordError: t('ERROR.500'), passwordUpdated: false });
        }
    }

    render() {
        const { password, replyPassword, passwordError, replyPasswordError, passwordUpdated } = this.state;
        const { t } = this.props;

        return (
            <>
                <Header type='simple' />
                {passwordUpdated ? (
                    <div className="password-updated-container">
                        <img
                            loading="lazy"
                            src={succesIcon}
                            className="success-icon"
                            alt="Password updated successfully"
                        />
                        <h2 className="success-title">{t('PASSWORD_MODIFY.MODIFIED.LABEL.Title')}</h2>
                        <p className="success-description">{t('PASSWORD_MODIFY.MODIFIED.LABEL.Description')}</p>
                        <button onClick={this.handleLoginClick} className="login-button">{t('PASSWORD_MODIFY.MODIFIED.BUTTON.InitSession')}</button>
                    </div>
                ) : (
                    <div className="modify-password-content">
                        <h1 className="title">{t('PASSWORD_MODIFY.MODIFY.LABEL.Title')}</h1>
                        <p className="description">{t('PASSWORD_MODIFY.MODIFY.LABEL.Description')}</p>
                        <form className="modify-password-form" onSubmit={this.handleSubmit}>
                            <label className="label">{t('PASSWORD_MODIFY.MODIFY.LABEL.InsertPassword')}</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                className={`input ${passwordError ? 'error' : ''}`}
                                placeholder={t('PASSWORD_MODIFY.MODIFY.LABEL.NewPassword')}
                                aria-label={t('PASSWORD_MODIFY.MODIFY.LABEL.NewPassword')}
                                onBlur={() => this.handleBlur('password')}
                                onChange={(e) => this.handleChange('password', e.target.value)}
                            />
                            {passwordError && <p className="error-message">{passwordError}</p>}
                            <input
                                type="password"
                                id="replyPassword"
                                value={replyPassword}
                                className={`input ${replyPasswordError ? 'error' : ''}`}
                                placeholder={t('PASSWORD_MODIFY.MODIFY.LABEL.ReplyPassword')}
                                aria-label={t('PASSWORD_MODIFY.MODIFY.LABEL.ReplyPassword')}
                                onBlur={() => this.handleBlur('replyPassword')}
                                onChange={(e) => this.handleChange('replyPassword', e.target.value)}
                            />
                            {replyPasswordError && <p className="error-message">{replyPasswordError}</p>}
                            <button className="modify-password-button">{t('PASSWORD_MODIFY.MODIFY.BUTTON.Modify')}</button>
                        </form>
                    </div>
                )}
                <SimpleFooter />
            </>
        );
    }
}

export default withTranslation()(ModifyPassword);
