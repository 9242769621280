import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videocallInfo: null,
};

const videocallSlice = createSlice({
  name: 'videcall',
  initialState,
  reducers: {
    setVideocallInfo(state, action) {
      state.videocallInfo = action.payload;
    },
    clearVideocallInfo(state) {
      state.videocallInfo = null;
    },
  },
});

export const { setVideocallInfo, clearVideocallInfo } = videocallSlice.actions;
export default videocallSlice.reducer;
