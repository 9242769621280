import React, { useState } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../modal/modal.component';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@iconify/react';
import styles from './styles/uploadFileModal.module.scss';

const UploadFileModal = ({ t, onClose, onFileSelect }) => {
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const MAX_FILE_SIZE = 5 * 1024 * 1024; //5MB
  const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/gif"];

  const MIME_TYPE_TO_EXTENSION = {
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "image/gif": ".gif"
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(t("UPLOADFILE.FileTooLarge", { maxSize: "5MB" }));
        return;
      }

      if (!ALLOWED_MIME_TYPES.includes(file.type)) {
        const allowedExtensions = ALLOWED_MIME_TYPES.map(type => MIME_TYPE_TO_EXTENSION[type]).join(", ");
        setErrorMessage(t("UPLOADFILE.InvalidFileType", { allowedTypes: allowedExtensions }));
        return;
      }

      setFileName(file.name);
      setErrorMessage("");
      onFileSelect(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': []
    }
  });

  const buttons = [
    { label: t("UPLOADFILE.SaveButton"), className: styles.saveButton, onClick: () => onClose(true) },
    { label: t("UPLOADFILE.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
  ];

  return (
    <Modal title={t("UPLOADFILE.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
      <div className={styles.dropBox} {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon icon="bxs:user-account" className={styles.icon} />
        {isDragActive ? (
          <p className={styles.fileName}>{t("UPLOADFILE.DropActive")}</p>
        ) : (
          ""
        )}
        {fileName && <p className={styles.fileName}>{fileName}</p>}
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      </div>
    </Modal>
  );
}

export default withTranslation()(UploadFileModal);
