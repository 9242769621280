import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedContact: null,
  contacts: [],
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setSelectedContact(state, action) {
      state.selectedContact = action.payload;
    },
    clearSelectedContact(state) {
      state.selectedContact = null;
    },
    setContacts(state, action) {
      state.contacts = action.payload;
    },
    clearContacts(state) {
      state.contacts = [];
    },
  },
});

export const { setSelectedContact, clearSelectedContact, setContacts, clearContacts } = contactSlice.actions;
export default contactSlice.reducer;
