import React, {useEffect} from 'react';
import './styles/App.scss';
import Router from './Routes/Router.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import {I18nextProvider} from 'react-i18next';
import i18n from 'i18next';
import {connect, useSelector} from 'react-redux';
import {askNotificationPermission, onMessageListener, requestToken} from './services/notification/FirebaseService';
import {addNotificationToStart, clearLastNotification, setLastNotification} from './redux/slices/notificationSlice';
import WebSocketService from './services/WebSocketService';
import NotificationPopup from './components/notification-popup/notificationPopup.component';
import {persistor} from "./redux/store";

// Configuración de i18n
i18n.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    en: { translation: require('./i18n/en.json') },
    es: { translation: require('./i18n/es.json') },
  },
});



function App({ userId, userState, tenant, addNotificationToStart, lastNotification, setLastNotification, clearLastNotification }) {
  const language = useSelector((state) => state.language.language);
  const session = useSelector((state) => state.session.session);

  useEffect(() => {
    localStorage.removeItem('persist:root');
    persistor.flush()
      .then(() => persistor.purge())
      .then(() => {
        console.log("Persistor purged and localStorage cleared");
      })
      .catch((error) => {
        console.error("Purge error:", error);
      });
  }, []);

  // Cambiar el idioma basado en el store
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  // Inicializar notificaciones push
  useEffect(() => {
    const initNotifications = async () => {
      const permissionGranted = await askNotificationPermission();
      console.log("Permiso de notificaciones:", permissionGranted);
      if (permissionGranted) {
        await requestToken();
      }
    };

    if (tenant && !session) {
      initNotifications();
    }

    if (session) {
      onMessageListener()
        .then(async (payload) => {
          console.log("Mensaje recibido en primer plano:", payload);
          await setLastNotification(payload.data);
        })
        .catch((err) => console.error('Error recibiendo notificación:', err));
    }
  }, [tenant, session, setLastNotification]);

  // Registro del Service Worker para Firebase
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registrado:', registration);
        })
        .catch((err) => {
          console.error('Error al registrar el Service Worker:', err);
        });

            if (!navigator.serviceWorker.messageListenerAdded) {
                navigator.serviceWorker.addEventListener('message', (event) => {
                    if (event.data && event.data.type === 'NEW_NOTIFICATION') {
                        console.log('Notificación recibida desde el SW:', event.data.payload);

                        console.log('Añadiendo notificación al store:', event.data.payload);
                        addNotificationToStart(event.data.payload);
                    }
                });

                navigator.serviceWorker.messageListenerAdded = true;
            }
        }
    }, [addNotificationToStart]);
    useEffect(() => {
        const tokenRefreshInterval = setInterval(() => {
          requestToken().then(token => {
            if (token) {
              console.log('Token actualizado:', token);
            }
          });
        }, 30 * 60 * 1000); // 30 minutos

        return () => clearInterval(tokenRefreshInterval);
    }, []);

    useEffect(() => {
        if (lastNotification) {
            const timer = setTimeout(() => {
                clearLastNotification();
            }, 60000); // 1 minuto

            return () => clearTimeout(timer);
        }
    }, [lastNotification, clearLastNotification]);

  useEffect(() => {
    let intervalId = null;

    const connectAndSend = async () => {
      try {

        if (WebSocketService.connectionState !== 'CONNECTED') {
          await WebSocketService.connectToStomp();
        }

        if (userId && tenant) {
          intervalId = setInterval(() => {
            const message = {
              id: userId,
              tenant: tenant,
              state: userState,
              timestamp: Date.now(),
            };
            WebSocketService.send('/topic/user', JSON.stringify(message));
          }, 20000);
        }
      } catch (error) {
        console.error('Error connecting to WebSocket:', error);
      }
    };

    connectAndSend();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      WebSocketService.disconnect();
    };
  }, [userId, tenant]);


  return (
    <I18nextProvider i18n={i18n}>
      <main>
        <Router />
        {lastNotification && (
          <NotificationPopup
            title={lastNotification.title}
            body={lastNotification.body}
            onClose={() => clearLastNotification()}
          />
        )}
      </main>
    </I18nextProvider>
  );
}



const mapStateToProps = (state) => ({
  userId: state.user.id,
  userState: state.user.userStatus,
  tenant: state.tenant?.tenant,
  lastNotification: state.notifications.lastNotification,
});

const mapDispatchToProps = {
  addNotificationToStart,
  clearLastNotification,
  setLastNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
