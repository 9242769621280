import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import styles from './styles/homesModal.module.scss';

class HomesModal extends Component {
    render() {
        const { t, onClose, homes } = this.props;
        const buttons = [
            { label: t("USERS.HOMESMODAL.SaveButton"), className: styles.saveButton, onClick: () => onClose(false) },
        ];
        return (
            <Modal title={t("USERS.HOMESMODAL.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
                <div className={styles.messages}>
                    <p>{t("USERS.HOMESMODAL.Message")}</p>
                </div>
                <div className={styles.tableContainer}>
                    <table className={styles.homesTable}>
                        <tbody>
                            {homes.map(home => (
                                <tr key={home.id}>
                                    <td>
                                        <div className={styles.homeInfo}>
                                            <div className={styles.homesTitle}>{home.name}</div>
                                            <div className={styles.homesDescription}>{home.niu}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {home.address}, {home.locality}, {home.province}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(HomesModal);