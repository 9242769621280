import React, { Component } from "react";
import { connect } from "react-redux";
import styles from './styles/configProfile.module.scss';
import { withTranslation } from "react-i18next";
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import CustomInput from '../../custom-input/customInput.component';
import { setLanguage } from '../../../redux/slices/languageSlice';
import { setTimeZone } from '../../../redux/slices/timeZoneSlice';
import { updateUserByUser } from '../../../services/UserService';
import CustomModal from "../../custom-modal/customModal.component";
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';

class ConfigProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                emailNotifications: false,
                regionLanguage: null,
                timeZone: null,
                password: "",
            },
            editingField: null,
            fieldValues: {},
            regions: [
                { key: 'es-ES', value: 'Español' },
                { key: 'en-EN', value: 'English' },
            ],
            timeZones: [
                { key: 'GMT+5', value: 'GMT+5' },
                { key: 'GMT+4', value: 'GMT+4' },
                { key: 'GMT+3', value: 'GMT+3' },
                { key: 'GMT+2', value: 'GMT+2' },
                { key: 'GMT+1', value: 'GMT+1' },
            ],
            isFeedbackModalOpen: false,
            feedbackType: null,
            feedbackMessage: '',
            showUpdateConfirmationModal: false,
            isLoading: false,
        };
    }

    componentDidMount() {
        const { language, timeZone } = this.props;
        this.setState({
            fields: {
                ...this.state.fields,
                regionLanguage: this.state.regions.find(region => region.key === language),
                timeZone: this.state.timeZones.find(zone => zone.key === timeZone)
            }
        });
    }

    handleCheckboxChange = (field) => {
        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                [field]: !prevState.fields[field],
            },
        }));
    };

    handleEditClick = (field) => {
        this.setState({
            editingField: field,
            fieldValues: { ...this.state.fieldValues, [field]: this.state.fields[field] }
        });
    };

    handleCancelClick = () => {
        this.setState({ editingField: null });
    };

    handleSaveClick = (field) => {
        this.setState((prevState) => ({
            fields: { ...prevState.fields, [field]: prevState.fieldValues[field] },
            editingField: null
        }));
    };

    handleInputChange = (value, field) => {
        this.setState({
            fieldValues: { ...this.state.fieldValues, [field]: value }
        });
    };

    setSelectedRegionLanguage = (selectedOptions) => {
        const selectedRegion = Array.isArray(selectedOptions) ? selectedOptions[0] : null;
        this.setState({ fields: { ...this.state.fields, regionLanguage: selectedRegion } });
    };

    setSelectedTimeZone = (selectedOptions) => {
        const selectedTimeZone = Array.isArray(selectedOptions) ? selectedOptions[0] : null;
        this.setState({ fields: { ...this.state.fields, timeZone: selectedTimeZone } });
    };

    handleShowConfirmation = () => {
        this.setState({ showUpdateConfirmationModal: true });
    };

    handleCancelUpdate = () => {
        this.setState({ showUpdateConfirmationModal: false });
    };

    handleSaveProfile = async () => {
        const { fields } = this.state;
        const { setLanguage, setTimeZone } = this.props;

        const data = {
            password: fields.password,
        };

        this.setState({ showUpdateConfirmationModal: false, isLoading: true });

        try {
            await updateUserByUser(data);
            if (fields.regionLanguage) {
                setLanguage(fields.regionLanguage.key);
            }
            if (fields.timeZone) {
                setTimeZone(fields.timeZone.key);
            }

            this.setState({
                isFeedbackModalOpen: true,
                feedbackType: 'ok',
                feedbackMessage: this.props.t("CONFIG.LABEL.SuccessMessage"),
            });
        } catch (error) {
            console.error('Error actualizando configuración:', error);
            this.setState({
                isFeedbackModalOpen: true,
                feedbackType: 'error',
                feedbackMessage: this.props.t("CONFIG.LABEL.ErrorMessage"),
            });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    closeFeedbackModal = () => {
        this.setState({ isFeedbackModalOpen: false });
    };

    render() {
        const { t } = this.props;
        const { fields, editingField, fieldValues, regions, timeZones, showUpdateConfirmationModal, isFeedbackModalOpen, feedbackType, feedbackMessage, isLoading } = this.state;

        const updateConfirmationButtons = [
            { label: t("CONFIG.LABEL.Confirm"), className: styles.saveButton, onClick: this.handleSaveProfile },
            { label: t("CONFIG.LABEL.Cancel"), className: styles.cancelButton, onClick: this.handleCancelUpdate }
        ];

        return (
            <div className={styles.configProfile}>
                {isLoading && <LoadingScreen />}
                <div className={styles.title}>{t("CONFIG.LABEL.Title")}</div>
                <table className={styles.table}>
                    <tbody>
                        <tr className={`${styles.tableRow} ${styles.disabled}`}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("CONFIG.LABEL.EmailNotifications")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <input
                                    type="checkbox"
                                    checked={fields.emailNotifications}
                                    className={styles.checkbox}
                                    onChange={() => this.handleCheckboxChange("emailNotifications")}
                                    disabled={true}

                                />
                            </td>
                        </tr>
                        <tr className={styles.tableRow}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("CONFIG.LABEL.RegionLanguage")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <DropdownSelect
                                    options={regions}
                                    isMultiple={false}
                                    placeholder={t("CONFIG.LABEL.SelectRegionLanguage")}
                                    setSelectedOptions={this.setSelectedRegionLanguage}
                                    selectedOptions={fields.regionLanguage ? [fields.regionLanguage] : []}
                                    customClass={styles.select}
                                    searchable={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr className={styles.tableRow}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("CONFIG.LABEL.TimeZone")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                <DropdownSelect
                                    options={timeZones}
                                    isMultiple={false}
                                    placeholder={t("CONFIG.LABEL.SelectTimeZone")}
                                    setSelectedOptions={this.setSelectedTimeZone}
                                    selectedOptions={fields.timeZone ? [fields.timeZone] : []}
                                    customClass={styles.select}
                                    searchable={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={styles.title}>{t("CONFIG.LABEL.Privacy")}</div>
                <table className={styles.table}>
                    <tbody>
                        <tr className={styles.tableRow}>
                            <td className={styles.tableCell}>
                                <label className={styles.label}>{t("CONFIG.LABEL.Password")}</label>
                            </td>
                            <td className={styles.tableCell}>
                                {editingField === "password" ? (
                                    <CustomInput
                                        type="password"
                                        name="password"
                                        value={fieldValues.password}
                                        onChange={(value) => this.handleInputChange(value, "password")}
                                        customClass={styles.inputField}
                                    />
                                ) : (
                                    <div className={styles.value}>********</div>
                                )}
                            </td>
                            <td className={styles.tableCell}>
                                {editingField === "password" ? (
                                    <div className={styles.buttonGroup}>
                                        <span className={styles.editLabel} onClick={() => this.handleSaveClick("password")}>{t("CONFIG.LABEL.Save")}</span>
                                        <span className={styles.editLabel} onClick={this.handleCancelClick}>{t("CONFIG.LABEL.Cancel")}</span>
                                    </div>
                                ) : (
                                    <span className={styles.editLabel} onClick={() => this.handleEditClick("password")}>{t("CONFIG.LABEL.Edit")}</span>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className={`${styles.row} ${styles.buttonRow}`}>
                    <div className={styles.buttonGroup}>
                        <button className={styles.saveButton} onClick={this.handleShowConfirmation}>{t("CONFIG.LABEL.Save")}</button>
                        <button className={styles.cancelButton}>{t("CONFIG.LABEL.Cancel")}</button>
                    </div>
                </div>

                {showUpdateConfirmationModal && (
                    <CustomModal
                        title={t("CONFIG.LABEL.EditConfirmationTitle")}
                        customText={t("CONFIG.LABEL.EditConfirmationMessage")}
                        icon="mdi:account-edit"
                        iconClassName={styles.updateIcon}
                        buttons={updateConfirmationButtons}
                        onClose={this.handleCancelUpdate}
                    />
                )}

                {isFeedbackModalOpen && (
                    <ModalFeedback
                        type={feedbackType}
                        customText={feedbackMessage}
                        onClose={this.closeFeedbackModal}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.language.language,
    timeZone: state.timeZone.timeZone,
});

const mapDispatchToProps = {
    setLanguage,
    setTimeZone
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfigProfile));
