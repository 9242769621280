import React, { Component } from "react";
import { Icon } from '@iconify/react';
import styles from './styles/helpSection.module.scss';
import { withTranslation } from "react-i18next";

class HelpSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {
                termsAndConditions: false,
                privacyPolicy: false,
                cookiePolicy: false,
                supportContact: false,
            }
        };
    }

    toggleSection = (section) => {
        this.setState((prevState) => ({
            sections: {
                ...prevState.sections,
                [section]: !prevState.sections[section]
            }
        }));
    }

    render() {
        const { t } = this.props;
        const { sections } = this.state;

        return (
            <div className={styles.helpSection}>
                <div className={styles.title}>{t("HELP.TITLE.About")}</div>
                <table className={styles.table}>
                    <tbody>
                        {this.renderRow("TermsAndConditions", "termsAndConditions", sections.termsAndConditions, t("HELP.CONTENT.LoremIpsum"))}
                        {this.renderRow("PrivacyPolicy", "privacyPolicy", sections.privacyPolicy, t("HELP.CONTENT.LoremIpsum"))}
                        {this.renderRow("CookiePolicy", "cookiePolicy", sections.cookiePolicy, t("HELP.CONTENT.LoremIpsum"))}
                        {this.renderRow("SupportContact", "supportContact", sections.supportContact, t("HELP.CONTENT.LoremIpsum"))}
                    </tbody>
                </table>
            </div>
        );
    }

    renderRow(labelKey, stateKey, isOpen, content) {
        const { t } = this.props;

        return (
            <>
                <tr className={styles.tableRow} onClick={() => this.toggleSection(stateKey)}>
                    <td className={styles.tableCell}>
                        <label className={styles.label}>{t(`HELP.LABEL.${labelKey}`)}</label>
                    </td>
                    <td className={styles.tableCell}>
                        <Icon icon="mingcute:right-line" className={`${styles.icon} ${isOpen ? styles.open : ''}`} />
                    </td>
                </tr>
                {isOpen && (
                    <tr className={styles.tableRowContent}>
                        <td className={styles.tableCellContent} colSpan="2">
                            {content}
                        </td>
                    </tr>
                )}
            </>
        );
    }
}

export default withTranslation()(HelpSection);
