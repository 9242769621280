import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import styles from './assets/styles/dashboard.module.scss';
import Header from '../../components/header/header.component';
import Menu from '../../components/menu/menu.component';
import {Action} from '../../utils/handleActionsEnum.js';
import {setSelectedComponent, setSelectedLayout} from '../../redux/slices/layoutSlice';
import {clearSelectedContact, setSelectedContact} from '../../redux/slices/contactSlice';
import {setSelectedProfileView} from '../../redux/slices/profileSlice';
import {clearVideocallInfo, setVideocallInfo} from '../../redux/slices/videocallSlice';
import WebSocketService from '../../services/WebSocketService.js';
import {Topic} from '../../utils/TopicEnum.js';
import {connect} from 'react-redux';
import {setUserHomes, setUserStatus} from '../../redux/slices/userSlice';
import {setUserTypes} from '../../redux/slices/userTypeSlice';
import IncomingVideoCall from '../../components/video-call-view/video-call-incoming/incomingVideoCall.component.jsx';
import {playRingtone} from '../../utils/videocall.util.js';
import {notifyVideoCallCancelation, notifyVideoCallRejection} from '../../services/VideoCallService.js';
import {changeUserStateToEndCall, changeUserStateToStartCall} from '../../services/UserStateService.js';
import {Outlet, useNavigate} from 'react-router-dom';
import {store} from '../../redux/store';
import {getContacts} from '../../services/UserService.js';
import {getHomesByUser} from '../../services/HomeService.js';
import {setContacts} from '../../redux/slices/contactSlice.js';
import Avatar from "../../components/avatar/avatar.component";
import {getImage} from "../../services/BlobService";
import RejectedVideoCall from "../../components/video-call-view/video-call-rejected/rejectedVideoCall.component";

const mapStateToProps = (state) => ({
    userEmail: state.user.email || "",
    userId: state.user.id,
    layout: state.layout.selectedLayout || 'horizontal',
    selectedComponent: state.layout.selectedComponent || 'contact',
    videocallInfo: state.videocall.videocallInfo || null,
    contacts: state.contact.contacts,
});

const mapDispatchToProps = (dispatch) => ({
    setUserHomes: (homes) => dispatch(setUserHomes({homes})),
    setUserTypes: (userTypes) => dispatch(setUserTypes({userTypes})),
    setSelectedContact: (contact) => dispatch(setSelectedContact(contact)),
    clearSelectedContact: () => dispatch(clearSelectedContact()),
    setSelectedProfileView: (view) => dispatch(setSelectedProfileView(view)),
    setVideocallInfo: (videocall) => dispatch(setVideocallInfo(videocall)),
    clearVideocallInfo: () => dispatch(clearVideocallInfo()),
    setSelectedLayout: (layout) => dispatch(setSelectedLayout(layout)),
    setSelectedComponent: (component) => dispatch(setSelectedComponent(component)),
    setContacts: (contacts) => dispatch(setContacts(contacts)),
    setUserStatus: (status) => dispatch(setUserStatus(status))
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: this.props.layout,
            selectedMenuComponent: this.props.selectedComponent,
            showIncomingVideoCall: false,
            showRejectedVideoCall: false,
            videocallSubscription: null,
            stateSubscription: null,
        };
        this.subscribeToRabbitTopic = this.subscribeToRabbitTopic.bind(this);
    }

    async subscribeToRabbitTopic() {
        try {
            console.debug('Intentando conectar al WebSocket...');
            await WebSocketService.connectToStomp();

            if (WebSocketService.connectionState !== 'CONNECTED') {
                console.error('WebSocket no está conectado. Intentando reconectar...');
                return; // Evitar la suscripción si no hay conexión
            }

            // Suscribir al tópico STATE
            console.debug('Conexión WebSocket exitosa. Suscribiendo al topic state...');
            const tenant = store.getState().tenant.tenant;
            const stateSubscription = WebSocketService.subscribeToTopic(
                Topic.STATE(tenant),
                this.contactStatusUpdatedEventListener.bind(this)
            );
            console.log('Suscripción al tópico STATE exitosa.');
            this.setState({stateSubscription});

            // Suscribir al tópico VIDEOCALL
            console.debug('Conexión WebSocket exitosa. Suscribiendo al topic videocall...');
            const videocallSubscription = WebSocketService.subscribeToTopic(
                Topic.VIDEOCALL(tenant),
                this.videoCallEventListener.bind(this)
            );
            console.log('Suscripción al tópico VIDEOCALL exitosa.');
            this.setState({videocallSubscription});

        } catch (error) {
            console.error('Error al conectar o suscribir al WebSocket:', error);
        }
    }

    contactStatusUpdatedEventListener(message) {
        try {
            const parsedMessage = JSON.parse(message.body);
            console.debug('[MESSAGE RECEIVED]', parsedMessage);
            const contacts = this.props.contacts;

            const updatedContacts = contacts.map(contact => {
                if (contact.id === parsedMessage.id) {
                    return {...contact, state: parsedMessage.state};
                }
                return contact;
            });

            if (JSON.stringify(updatedContacts) !== JSON.stringify(contacts)) {
                this.props.setContacts(updatedContacts);
            } else if (this.props.userId === parsedMessage.id) {
                this.props.setUserStatus({ status: parsedMessage.state });
            }
            else {
                console.warn(`No se encontró ningún contacto con el ID ${parsedMessage.id}`);
            }
        } catch (error) {
            console.error('Error al analizar el mensaje:', error);
        }
    }

    videoCallEventListener(message) {
        const videoCall = JSON.parse(JSON.parse(message.body)); // Asegúrate de que el formato de mensaje es correcto
        console.debug('[MESSAGE RECEIVED]', videoCall);

        if (videoCall.type === 'REJECTED_CALL') {
            if (this.props.videocallInfo && videoCall.user.email === this.props.userEmail && this.props.videocallInfo.roomId === videoCall.roomId) {
                console.log('Llamada rechazada por el usuario:', videoCall.rejectingUser);
                const storedVideoCall = {...this.props.videocallInfo, rejectingUser: videoCall.rejectingUser};
                playRingtone(false);
                this.props.setVideocallInfo(storedVideoCall);
                this.setState({showRejectedVideoCall: true});
            }
        } else if (videoCall.type === 'NEW_CALL') {
            console.log(`NEW_CALL recibido. Actual: ${this.props.userEmail}. Remitente: ${videoCall.user.email}. Destinatarios (${videoCall.recipients?.length}): ${videoCall.recipients[0]?.email}`, );
            if (videoCall.user.email === this.props.userEmail && !(videoCall.recipients?.some(recipient => recipient.email === this.props.userEmail))) {
                console.log('Iniciando nueva llamada saliente');
                changeUserStateToStartCall(this.props.userId);
                this.props.setVideocallInfo(videoCall);
                playRingtone(true);
                this.props.navigate('/videocall');
            } else if (videoCall.recipients?.some(recipient => recipient.email === this.props.userEmail)) {
                console.log('Recibiendo nueva llamada entrante');
                changeUserStateToStartCall(this.props.userId);
                this.props.setVideocallInfo(videoCall);
                this.setState({showIncomingVideoCall: true});
            }
        } else if (videoCall.type === 'ACCEPTED_CALL') {
            // Agregamos una verificación para evitar que se acepte más de una vez
            if (this.props.videocallInfo && videoCall.user.email === this.props.userEmail && this.props.videocallInfo.roomId === videoCall.roomId && !this.state.callAlreadyAccepted) {
                console.log('Llamada aceptada, manejando la aceptación');
                this.setState({callAlreadyAccepted: true}); // Marcamos la llamada como aceptada
                this.acceptedOutcomingVideoCall();
            } else {
                console.log('Llamada ya aceptada, evitando duplicados.');
            }
        } else if (videoCall.type === 'CANCELLED_CALL') {
            if (this.props.videocallInfo && videoCall.recipients.some(recipient => recipient.email === this.props.userEmail) && this.props.videocallInfo.roomId === videoCall.roomId) {
                console.log('Llamada cancelada, manejando la cancelación');
                this.cancelledIncomingVideoCall();
            }
        }
    }

    handleCallEnd = () => {
        console.log('La llamada ha terminado.');
        this.endCall();
    };

    async componentDidMount() {
        console.log("componentDidMount ejecutado en Dashboard");

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log("getUserMedia está disponible.");
        } else {
            console.error("getUserMedia no está disponible.");
        }
        const response = await getContacts(false);
        this.props.setContacts(response);

        const homes = await getHomesByUser();
        this.props.setUserHomes(homes);
        await this.subscribeToRabbitTopic();
    }

    componentWillUnmount() {
        console.log("Dashboard desmontado");
        if (this.state.stateSubscription) {
            this.state.stateSubscription.unsubscribe();
            this.setState({stateSubscription: null});
        }
        if (this.state.videocallSubscription) {
            this.state.videocallSubscription.unsubscribe();
            this.setState({videocallSubscription: null});
        }
        WebSocketService.disconnect();
    }

    handleActionClick = (action, object) => {
        console.log(action, object);
        switch (action) {
            case Action.CLEAN:
                this.props.clearSelectedContact();
                break;
            case Action.CONTACTS:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'contact'});
                this.props.navigate('/contacts');
                break;
            case Action.VIDEOCALL:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'videocall'});
                this.props.navigate('/videocalls');
                break;
            case Action.USER_DETAIL:
                this.props.setSelectedContact(object);
                this.setState({selectedMenuComponent: 'contact'});
                this.props.navigate('/contacts');
                break;
            case Action.USER_DETAIL_HISTORY:
                this.props.setSelectedContact(object);
                this.setState({selectedMenuComponent: 'videocall'});
                this.props.navigate('/videocalls');
                break;
            case Action.HOMES:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'homes'});
                this.props.navigate('/homes');
                break;
            case Action.CONTENT:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'content'});
                this.props.navigate('/content');
                break;
            case Action.USERS:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'users'});
                this.props.navigate('/users');
                break;
            case Action.CALENDAR:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'calendar'});
                this.props.navigate('/calendar');
                break;
            case Action.PROFILE:
                this.props.clearSelectedContact();
                this.props.setSelectedProfileView(object);
                this.setState({selectedMenuComponent: 'contact'});
                this.props.navigate('/profile');
                break;
            default:
                this.props.clearSelectedContact();
                this.setState({selectedMenuComponent: 'contact'});
                this.props.navigate('/contacts');
                break;
        }
        this.props.setSelectedComponent(this.state.selectedMenuComponent);
    }

    handleLayoutChange = (layout) => {
        this.setState({layout});
        this.props.setSelectedLayout(layout);
    }

    closeIncomingVideoCallModal = () => {
        playRingtone(false);
        this.setState({showIncomingVideoCall: false});
        notifyVideoCallRejection(this.props.videocallInfo);
        this.props.clearVideocallInfo();
        changeUserStateToEndCall(this.props.userId);
    }
    cancelOutcomingVideoCallModal = () => {
        playRingtone(false);
        notifyVideoCallCancelation(this.props.videocallInfo);
        this.setState({showOutcomingVideoCall: false});
        changeUserStateToEndCall(this.props.userId);
        this.props.clearVideocallInfo();
    }
    cancelledIncomingVideoCall = () => {
        playRingtone(false);
        this.setState({showIncomingVideoCall: false});
        changeUserStateToEndCall(this.props.userId);
        this.props.clearVideocallInfo();
    }

    acceptedOutcomingVideoCall = () => {
        playRingtone(false);
        //notifyVideoCallAcceptation(this.props.videocallInfo);
        changeUserStateToStartCall(this.props.userId);
        this.setState({showOutcomingVideoCall: false});
        this.props.navigate('/videocall');
    }

    acceptIncomingVideoCall = () => {
        playRingtone(false);
        //notifyVideoCallAcceptation(this.props.videocallInfo);
        changeUserStateToStartCall(this.props.userId);
        this.setState({showIncomingVideoCall: false});
        this.props.navigate('/videocall');
    }

    closeRejectedVideoCall = () => {
        this.setState({showRejectedVideoCall: false});
        changeUserStateToEndCall(this.props.userId);
        this.props.navigate(-1);
        this.props.clearVideocallInfo();
    }

    handleIconClick = (view, object) => {
        let layout;
        switch (view) {
            case 'calendar':
                layout = 'vertical';
                break;
            case 'profile':
                layout = 'horizontal';
                break;
            default:
                layout = 'horizontal';
                break;
        }
        this.setState({layout});
        this.props.setSelectedLayout(layout);

        if (view === 'profile') {
            this.handleActionClick(Action.CONTACTS);
            setTimeout(() => {
                this.handleActionClick(Action.PROFILE, object);
            }, 0);
        } else {
            this.handleLayoutChange(layout);
            this.handleActionClick(view.toUpperCase(), object);
        }
    };

    async fetchImage(imageId) {
        if (imageId) {
            try {
                return await getImage(imageId);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        }
    };

    render() {
        const {selectedMenuComponent, showIncomingVideoCall, showRejectedVideoCall} = this.state;
        const {t, videocallInfo,userId} = this.props;

      if (!userId) {
        return null;
      }

      return (
            <>
                <Header onIconClick={this.handleIconClick} selectedComponent={selectedMenuComponent}/>
                <div className={`${styles.dashboardContainer} ${styles[this.props.layout]}`}>
                    <div className={`${styles.menuContainer} ${styles[this.props.layout]}`}>
                        <Menu
                            handleActionClick={this.handleActionClick}
                            onLayoutChange={this.handleLayoutChange}
                            selectedComponent={selectedMenuComponent}
                        />
                    </div>
                    <div className={styles.mainContainer}>
                        <Outlet/>
                    </div>
                </div>
                {showIncomingVideoCall &&
                    <IncomingVideoCall
                        children={t("VIDEOCALL.INCOMING.Message")}
                        avatar={
                            <Avatar image={this.fetchImage(videocallInfo.user?.avatar)}
                                    name={videocallInfo.user?.alias}
                                    backgroundColor={videocallInfo.user?.backgroundColor}
                                    size='avatar-hard'
                            />
                        }
                        onClose={this.closeIncomingVideoCallModal}
                        onAccept={this.acceptIncomingVideoCall}
                        sender={videocallInfo.user}
                    />}

                {showRejectedVideoCall && <RejectedVideoCall
                    children={t("VIDEOCALL.REJECTED.Message")}
                    rejectingUser={videocallInfo.rejectingUser}
                    onClose={this.closeRejectedVideoCall}
                    buttons={
                        [{
                            label: t("CONTACT_DETAIL.BUTTON.success"),
                            className: styles.modalAcceptButton,
                            onClick: this.closeRejectedVideoCall
                        }]
                    }
                />}
            </>
        );
    }
}

function DashboardWithNavigate(props) {
    const navigate = useNavigate();
    return <Dashboard {...props} navigate={navigate}/>;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardWithNavigate));
