import React, { Component } from "react";
import { Icon } from '@iconify/react';
import styles from './styles/modal.module.scss';

class ActionButton extends Component {
  render() {
    const { label, className, title, icon, iconClassName, onClick } = this.props;
    return (
      <button
        type="button"
        className={`${styles.actionButton} ${className}`}
        title={title}
        onClick={onClick}
      >
        <Icon icon={icon} className={iconClassName} />
        {label}
      </button>
    );
  }
}

class Modal extends Component {

  handleOverlayClick = (e) => {
    const { onClose } = this.props;
    if (onClose && e.target === e.currentTarget) {
      onClose();
    }
  };

  render() {
    const { title, titleClassName, children, buttons, image, onClose, avatar } = this.props;

    return (
      <div className={styles.modalOverlay} onClick={this.handleOverlayClick}>
        <div className={styles.modal}>
          {onClose && (
            <button className={styles.closeButton} onClick={onClose}>
              <Icon icon="mdi:close" />
            </button>
          )}
          {avatar ? (
            <div className={styles.avatar}>
              {avatar}
            </div>
          ) : (
            image && (
              typeof image === 'string' ? (
                <img src={image} alt="modal" className={styles.modalImage} />
              ) : (
                <div className={styles.iconContainer}>
                  {image}
                </div>
              )
            )
          )}
          <h1 className={`${titleClassName}`} dangerouslySetInnerHTML={{ __html: title }}></h1>
          <div className={styles.modalContent}>
            {children}
          </div>
          {buttons && buttons.length > 0 && (
            <div className={styles.buttonGroup}>
              {buttons.map((button, index) => (
                <ActionButton
                  key={index}
                  label={button.label}
                  className={button.className}
                  title={button.title}
                  icon={button.icon}
                  iconClassName={button.iconClassName}
                  onClick={button.onClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Modal;
