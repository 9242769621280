import { store } from '../redux/store.js';
import { errorHandler } from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const PasswordService = {
    sendEmail: async (email) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/mail/recovery-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`
                },
                body: JSON.stringify({ emailTo: email })
            });

            errorHandler(response.status);

            return response;
        } catch (error) {
            console.error('Error sending email:', error);
            throw error;
        }
    }, 
    updatePassword: async (password, token) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/password/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ password })
            });

            errorHandler(response.status);
            
            return response;
        } catch (error) {
            console.error('Error updating password:', error);
            throw error;
        }
    }
}

export const { sendEmail, updatePassword } = PasswordService;
