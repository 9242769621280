import React, { Component, createRef } from "react";
import { Icon } from '@iconify/react';
import Modal from '../modal/modal.component';
import styles from './styles/modalFeedback.module.scss';
import { withTranslation } from "react-i18next";

class ModalFeedback extends Component {
  constructor(props) {
    super(props);
    this.modalRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      this.props.onClose();
    }
  };

  render() {
    const { type, customText, onClose, title, t } = this.props;

    const config = {
      error: {
        icon: <Icon icon="fluent-mdl2:status-error-full" className={styles.iconError} />,
        defaultText: t("MODAL.LABEL.ErrorMessage"),
        titleClassName: styles.title,
      },
      ok: {
        icon: <Icon icon="icon-park-solid:check-one" className={styles.iconSuccess} />,
        defaultText: t("MODAL.LABEL.SuccessMessage"),
        titleClassName: styles.title,
      }
    };

    const { icon, defaultText, titleClassName } = config[type] || config.ok;

    return (
      <div ref={this.modalRef}>
        <Modal
          title={title || defaultText}
          titleClassName={titleClassName}
          image={icon}
          onClose={onClose}
        >
          <p>{customText}</p>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(ModalFeedback);
