import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import styles from './styles/sendNewPasswordRequest.module.scss';

class SendNewPasswordRequest extends Component {
    handleConfirm = async () => {
        this.props.onClose(true);
    };

    render() {
        const { t, onClose } = this.props;
        const buttons = [
            { label: t("USERS.NEWPASS.SaveButton"), className: styles.saveButton, onClick: this.handleConfirm },
            { label: t("USERS.NEWPASS.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
        ];

        return (
            <Modal title={t("USERS.NEWPASS.RequestTitle")} titleClassName={styles.modalTitle} buttons={buttons}>
                <div className={styles.messages}>
                    <p>{t("USERS.NEWPASS.RequestMessage1")}</p>
                    <p>{t("USERS.NEWPASS.RequestMessage2")}</p>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(SendNewPasswordRequest);
