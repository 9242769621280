import React, {Component} from 'react';
import Calendar from '@toast-ui/react-calendar';
import './styles/toastUiCalendar.scss';
import {getContentByUserAndDate} from "../../../services/ContentService";
import {mapCategory} from "../../../utils/CategoryMapper.util";
import styles from './styles/customCalendar.module.scss';
import {Icon} from '@iconify/react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import AgendaView from '../agenda-view/agendaView.component';

class CustomCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'month',
            allEvents: [],
            filteredEvents: [],
            hasAllDayEvents: false
        };
    }

    componentDidMount() {
        this.loadEventsForMonth();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentDate !== this.props.currentDate) {
            this.loadEventsForMonth();
            console.log("CURRENT DATE", this.props.currentDate);
        }
    }

    async loadEventsForMonth() {
        try {
            const { currentDate } = this.props;
            const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            const eventsData = await getContentByUserAndDate(startDate, endDate);
            let hasAllDay = false;
            const allEvents = eventsData.map(event => {
                const start = new Date(event.startDate);
                const end = new Date(event.endDate);
                const isMultiDay = start.getDate() !== end.getDate() || start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear();
                const categoryInfo = mapCategory(event.category.name);
                if (isMultiDay) {
                    hasAllDay = true;
                }

                return {
                    id: String(event.id),
                    calendarId: '1',
                    title: event.name,
                    category: isMultiDay ? 'allday' : 'time',
                    categoryName: event.category.name,
                    start: new Date(event.startDate),
                    end: new Date(event.endDate),
                    backgroundColor: categoryInfo ? categoryInfo.color : '#000000',
                    isReadOnly: true,
                    isAllDay: isMultiDay,
                    icon: categoryInfo ? categoryInfo.icon : null,
                };
            });
            this.setState({ allEvents, hasAllDayEvents: hasAllDay }, this.filterEvents);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    }

    filterEvents = () => {
        const { view, allEvents } = this.state;
        const { currentDate } = this.props;
        let filteredEvents = [];
        let hasAllDayEvents = false;

        if (view === 'month') {
            filteredEvents = allEvents.map(event => ({
                ...event,
                isAllDay: true,
                category: 'allday'
            }));
            hasAllDayEvents = true;
        } else if (view === 'agenda') {
            filteredEvents = allEvents;
            hasAllDayEvents = allEvents.some(event => event.isAllDay);
        } else if (view === 'week') {
            const weekStart = new Date(currentDate);
            weekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1);
            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);
            filteredEvents = allEvents.filter(event =>
                (event.start >= weekStart && event.start <= weekEnd) ||
                (event.end >= weekStart && event.end <= weekEnd) ||
                (event.start < weekStart && event.end > weekEnd)
            );
            hasAllDayEvents = filteredEvents.some(event => event.isAllDay);
        } else if (view === 'day') {
            const dayStart = new Date(currentDate.setHours(0, 0, 0, 0));
            const dayEnd = new Date(currentDate.setHours(23, 59, 59, 999));
            filteredEvents = allEvents.filter(event =>
                (event.start >= dayStart && event.start <= dayEnd) ||
                (event.end >= dayStart && event.end <= dayEnd) ||
                (event.start < dayStart && event.end > dayEnd)
            ).map(event => {
                if (event.isAllDay) {
                    const eventStart = new Date(event.start);
                    const eventEnd = new Date(event.end);
                    const truncatedDay = this.getTotalDate(currentDate);
                    const startOfDay = new Date(truncatedDay.setHours(8, 0, 0, 0));
                    const endOfDay = new Date(truncatedDay.setHours(20, 0, 0, 0));
                    if (startOfDay.getTime() <= eventStart.getTime()) {
                        return { ...event, isAllDay: false, category: 'time', start: event.start, end: new Date(eventStart.setHours(20, 0, 0, 0)) };
                    } else if (endOfDay.getTime() >= eventEnd.getTime()) {
                        return { ...event, isAllDay: false, category: 'time', start: new Date(eventEnd.setHours(8, 0, 0, 0)), end: event.end };
                    } else {
                        return { ...event, isAllDay: true, category: 'allday' };
                    }
                }
                return event;
            });
            hasAllDayEvents = filteredEvents.some(event => event.isAllDay);
        }

        this.setState({ filteredEvents, hasAllDayEvents });
    }

    getTotalDate = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    handlePrevClick = () => {
        this.props.onPrev(this.state.view);
    }

    handleNextClick = () => {
        this.props.onNext(this.state.view);
    }

    handleViewChange = (view) => {
        this.setState({ view }, this.filterEvents);
    }

    formatDate = () => {
        const { view } = this.state;
        const { currentDate, language } = this.props;

        const formatDayMonthYear = (date) => {
            const day = date.getDate();
            const month = date.toLocaleDateString(language, { month: 'long' });
            const year = date.getFullYear();
            return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
        };

        const formatMonthYear = (date) => {
            const month = date.toLocaleDateString(language, { month: 'long' });
            const year = date.getFullYear();
            return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
        };

        switch (view) {
            case 'month':
            case 'agenda':
                return formatMonthYear(currentDate);
            case 'week':
                const weekStart = new Date(currentDate);
                weekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1);
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekStart.getDate() + 6);
                return `${weekStart.toLocaleDateString(language, { day: 'numeric', month: 'short' })} - ${weekEnd.toLocaleDateString(language, { day: 'numeric', month: 'short', year: 'numeric' })}`;
            case 'day':
                return formatDayMonthYear(currentDate);
            default:
                return formatMonthYear(currentDate);
        }
    }

    render() {
        const { t, currentDate, language, timeZone } = this.props;
        const { view, filteredEvents, hasAllDayEvents } = this.state;
        const formattedDate = this.formatDate();

        const template = {
            time: function (event) {
                return `<div class="custom-event" style="background-color: ${event.backgroundColor};">
                    <span class="event-title">${event.title}</span>
                </div>`;
            },
            allday: function (event) {
                return `<div class="custom-event" style="background-color: ${event.backgroundColor};">
                <span class="event-title">${event.title}</span>
            </div>`;
            },
            alldayTitle: function () {
                return `<span>${t("CALENDAR.LABEL.AllDay")}</span>`;
            },
            weekGridFooterExceed(hiddenEvents) {
                return `+${hiddenEvents}`;
            },
            monthGridHeaderExceed(hiddenEvents) {
                return `+${hiddenEvents}`;
            },
            timegridDisplayPrimaryTime({ time }) {
                const date = new Date(time.d.d);
                const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone };
                return date.toLocaleTimeString(language, options);
            },
            monthMoreTitleDate(moreTitle) {
                const { date } = moreTitle;
                const calendarDate = new Date(currentDate);
                const month = calendarDate.toLocaleString('default', { month: 'long' });

                return `<span>${date} ${month}</span>`;
            },

        };

        const eventView = hasAllDayEvents ? ['time', 'allday'] : ['time'];

        const weekDays = [
            t('CALENDAR.WEEKDAYS.Sunday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Monday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Tuesday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Wednesday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Thursday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Friday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Saturday').toUpperCase()
        ];

        const theme = {
            common: {
                holiday: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
                saturday: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
                sunday: {
                    color: '#1B1B1B',
                    backgroundColor: '#EEEEEE',
                },
                dayGrid: {
                    color: '#1B1B1B',
                },
                today: {
                    color: 'white',
                },
            },
            week: {

                timeGridHalfHourLine: {
                    borderBottom: '1px dotted #EEE',
                },
                nowIndicatorLabel: {
                    color: '#C40024',
                },
                nowIndicatorPast: { border: '1px dashed #C40024' },
                nowIndicatorBullet: { backgroundColor: '#C40024' },
                nowIndicatorToday: { border: '1px solid #C40024' },
                nowIndicatorFuture: { border: '1px solid #C40024' },
                weekend: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
                today: {
                    color: 'inherit',
                    backgroundColor: 'none',
                }
            },
            month: {
                weekend: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
            },
        };

        return (
            <div className={styles.calendarView}>
                <div className={styles.calendarContainer}>
                    <div className={styles.header}>
                        <div className={styles.dateSelector}>
                            <Icon icon="mingcute:down-line" className={styles.navButtonLeft} onClick={this.handlePrevClick} />
                            <span>{formattedDate}</span>
                            <Icon icon="mingcute:down-line" className={styles.navButtonRight} onClick={this.handleNextClick} />
                        </div>
                        <div className={styles.viewButtons}>
                            <button className={view === 'day' ? styles.active : ''} onClick={() => this.handleViewChange('day')}>{t('CALENDAR.LABEL.DAY')}</button>
                            <button className={view === 'week' ? styles.active : ''} onClick={() => this.handleViewChange('week')}>{t('CALENDAR.LABEL.WEEK')}</button>
                            <button className={view === 'month' ? styles.active : ''} onClick={() => this.handleViewChange('month')}>{t('CALENDAR.LABEL.MONTH')}</button>
                            <button className={view === 'agenda' ? styles.active : ''} onClick={() => this.handleViewChange('agenda')}>{t('CALENDAR.LABEL.AGENDA')}</button>
                        </div>
                    </div>
                    {view === 'agenda' ? (
                        <AgendaView
                            events={filteredEvents}
                            currentDate={this.props.currentDate}
                        />
                    ) : (
                        <Calendar
                            ref={this.props.calendarRef}
                            className={styles.calendar}
                            height="85dvh"
                            view={view}
                            events={filteredEvents}
                            template={template}
                            isReadOnly={true}
                            useDetailPopup={true}
                            week={{
                                startDayOfWeek: 1,
                                hourStart: 8,
                                hourEnd: 20,
                                taskView: false,
                                eventView: eventView,
                                dayNames: weekDays,
                            }}
                            day={{
                                hourStart: 8,
                                hourEnd: 20,
                                taskView: false,
                                eventView: eventView,
                            }}
                            month={{
                                startDayOfWeek: 1,
                                dayNames: weekDays,
                                narrowWeekend: false,
                                isAlways6Weeks: false,
                            }}
                            theme={theme}
                            calendars={[
                                {
                                    id: '1',
                                    name: 'Contenidos',
                                    color: '#ffffff',
                                    borderColor: 'none',
                                }
                            ]}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language.language,
    timeZone: state.timeZone.timeZone,
});

export default connect(mapStateToProps)(withTranslation()(CustomCalendar));
