import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import styles from './styles/sendNewPasswordSelection.module.scss';
import { getContactsByUserId, sendPasswords } from '../../../services/UserService';
import contactMail from '../../../assets/img/contactMail.svg';

class SendNewPasswordSelection extends Component {
    state = {
        contacts: [],
        selectedContactIds: [],
        error: false
    };

    async componentDidMount() {
        const contacts = await getContactsByUserId(this.props.userId);
        this.setState({ contacts });
    }

    handleConfirm = async () => {
        if (this.state.selectedContactIds.length !== 0) {
            await sendPasswords(this.props.userId, this.props.password, this.state.selectedContactIds)
            this.props.onClose(true);
        } else {
            this.setState({ error: true });
        }
    };

    handleCancel = () => {
        this.props.onClose(false);
    };

    handleCheckboxChange = (contactId) => {
        this.setState((prevState) => {
            const isSelected = prevState.selectedContactIds.includes(contactId);
            const selectedContactIds = isSelected
                ? prevState.selectedContactIds.filter(id => id !== contactId)
                : [...prevState.selectedContactIds, contactId];
            return { selectedContactIds };
        });
    };

    render() {
        const { t } = this.props;
        const { contacts, selectedContactIds, error } = this.state;
        const buttons = [
            { label: t("USERS.NEWPASS.SaveButton"), className: styles.saveButton, onClick: this.handleConfirm },
            { label: t("USERS.NEWPASS.CancelButton"), className: styles.cancelButton, onClick: this.handleCancel }
        ];

        return (
            <Modal title={t("USERS.NEWPASS.SelectionTitle")} titleClassName={styles.modalTitle} buttons={buttons}>
                <div className={styles.messages}>
                    <p>{t("USERS.NEWPASS.SelectionMessage1")}</p>
                    <p>{t("USERS.NEWPASS.SelectionMessage2")}</p>
                </div>
                <div className={styles.tableTitle}>
                <img
                    src={contactMail}
                    alt="Contact"
                    className={styles.image}
                    />
                    <p>{t("USERS.NEWPASS.SelecionContact")}</p>
                </div>
                <div className={styles.tableContainer}>
                    <table className={styles.usersTable}>
                        <tbody>
                            {contacts.map(contact => (
                                <tr key={contact.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            className={styles.checkbox}
                                            checked={selectedContactIds.includes(contact.id)}
                                            onChange={() => this.handleCheckboxChange(contact.id)}
                                        />
                                    </td>
                                    <td>
                                        <div className={styles.contactInfo}>
                                            <div className={styles.usersTitle}>{contact.homes[0].name}</div>
                                            <div className={styles.usersDescription}>{contact.homes[0].ref}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={styles.contactInfo}>
                                            <div className={styles.usersTitle}>{t("USER_TYPE."+contact.userType)}</div>
                                            <div className={styles.usersDescription}>{contact.name + " " + contact.surname }</div>
                                        </div>
                                    </td>
                                    <td className={styles.textEmail}>{contact.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {error && 
                        <p className={styles.errorMessage}>{t("USERS.NEWPASS.ErrorMessage")}</p>
                    }
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(SendNewPasswordSelection);
