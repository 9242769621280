import { store } from '../../redux/store.js';
import { errorHandler } from '../../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const NotificationService = {
    getNotifications: async (limit, offset) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            let response;
            console.log('limit', limit, 'offset', offset);
            if (limit || offset) {
                response = await fetch(`${API_URL}/api/v1/notifications?limit=${limit}&offset=${offset}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Tenant-ID': `${tenantName}`,
                        'Authorization': `Bearer ${token}`
                    }
                });
            } else {
                response = await fetch(`${API_URL}/api/v1/notifications`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Tenant-ID': `${tenantName}`,
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
            if (response) {
                errorHandler(response.status);
                if (response.ok) {
                    return response.json();
                }
                return [];
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    },

    createNotification: async (notificationData) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/notifications`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(notificationData)
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            throw new Error('Failed to create notification');
        } catch (error) {
            console.error('Error creating notification:', error);
            throw error;
        }
    },

    createBulkNotifications: async (notificationData) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/notifications/bulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(notificationData)
            });

            errorHandler(response.status);

            if (response.ok) {
                return;
            }
            throw new Error('Failed to create notifications');
        } catch (error) {
            console.error('Error creating notifications:', error);
            throw error;
        }
    },

    markNotificationAsRead: async (notificationId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/notifications/${notificationId}/read`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to mark notification as read');
        } catch (error) {
            console.error('Error marking notification as read:', error);
            throw error;
        }
    },

    markAllNotificationsAsRead: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/notifications/read-all`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to mark all notifications as read');
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
            throw error;
        }
    },

    deleteAllNotifications: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let token = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/notifications/delete-all`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to delete all notifications');
        } catch (error) {
            console.error('Error deleting all notifications:', error);
            throw error;
        }
    },

    sendDeviceToken: async (token) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let authToken = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/sessions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    token: token,
                    type: "web",
                })
            });

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to send device token to backend');
        } catch (error) {
            console.error('Error sending device token:', error);
            throw error;
        }
    },

    deleteDeviceToken: async (token) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let authToken = localStorage.getItem('accessToken');
            const response = await fetch(`${API_URL}/api/v1/sessions/${token}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (response.ok) {
                return true;
            }
            throw new Error('Failed to delete device token from backend');
        } catch (error) {
            console.error('Error deleting device token:', error);
            throw error;
        }
    }
};

export const { getNotifications, createNotification, markNotificationAsRead, sendDeviceToken, deleteDeviceToken, createBulkNotifications, markAllNotificationsAsRead, deleteAllNotifications } = NotificationService;
