const userTypeMap = (t) => ({
    USER: t('USER_TYPE.User'),
    FAMILY: t('USER_TYPE.Family'),
    PROFESSIONAL: t('USER_TYPE.Professional'),
    MANAGER: t('USER_TYPE.Manager'),
    ADMIN: t('USER_TYPE.Admin')
});

export const getUserTypeTranslation = (t, userType) => {
    if (!userType) return t('USER_TYPE.Undefined');
    const translatedUserType = userTypeMap(t)[userType.toUpperCase()];
    return translatedUserType || userType;
};

const stateMap = (t) => ({
    ONLINE: t('STATE.Online'),
    OFFLINE: t('STATE.Offline'),
    BUSY: t('STATE.Busy')
});

export const getStateTranslation = (t, state) => {
    const translatedState = stateMap(t)[state];
    return translatedState || state;
};

const stateCallMap = (t) => ({
    LOST: t('STATE-CALL.Lost'),
    COMPLETED: t('STATE-CALL.Completed'),
    ON_CALL: t('STATE-CALL.OnCall')
});

export const getStateCallTranslation = (t, state) => {
    const translatedState = stateCallMap(t)[state];
    return translatedState || state;
};