import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import styles from './styles/sendNewPasswordConfirmation.module.scss';
import partyPopper from '../../../assets/img/partyPopper.png';

class SendNewPasswordConfirmation extends Component {
    handleConfirm = async () => {
        this.props.onClose(true);
    };

    render() {
        const { t } = this.props;
        const buttons = [
            { label: t("USERS.NEWPASS.SaveButton"), className: styles.saveButton, onClick: this.handleConfirm },
        ];

        return (
            <Modal title={t("USERS.NEWPASS.ConfirmationTitle")} titleClassName={styles.modalTitle} buttons={buttons} image={partyPopper}>
                <div className={styles.messages}>
                    <p>{t("USERS.NEWPASS.ConfirmationMessage1")}</p>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(SendNewPasswordConfirmation);
