import React, {Component} from 'react';
import {auth, checkAuth} from '../../services/auth/AuthService';
import {withTranslation} from 'react-i18next';
import './assets/styles/login.scss';
import logoTunstall from '../../assets/img/tunstall.svg';
import {validEmail} from "../../utils/validation.util";
import {store} from '../../redux/store';
import WebSocketService from '../../services/WebSocketService.js';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: null,
      usernameError: null,
      isUsernameValid: false
    };
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
      error: null
    });
  };

  handleBlur = (e) => {
    const { id, value } = e.target;
    const { t } = this.props;
    if (value !== '' && id === 'username' && !validEmail(value)) {
      this.setState({
        [id]: value,
        usernameError: t('LOGIN.ERROR.Email'),
        isUsernameValid: false,
        error: null
      });
    } else {
      this.setState({
        [id]: value,
        usernameError: null,
        error: null,
        isUsernameValid: true
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password, isUsernameValid } = this.state;
    const { t } = this.props;
    if (!isUsernameValid) {
      return;
    }
    if (!username || !password) {
      this.setState({ error: t('LOGIN.ERROR.EmptyFields') });
    } else {
      await this.executeLogin(username, password);
    }
  };

  executeLogin = async (username, password) => {
    const { t } = this.props;
    try {
      const response = await auth(username, password);
      if (response.status === 500) {
        this.setState({ error: t('ERROR.500') });
      } else if (response.status === 401) {
        console.error('Usuario no autorizado: Verifique sus credenciales');
        this.setState({ error: t('ERROR.401') });
      } else if (response.status === 200) {
        this.goToProfileSelector();
      }
    } catch (error) {
      console.error('Error de autenticación:', error);
      this.setState({ error: t('ERROR.500') });
    }
  };

  goToProfileSelector = () => {
    WebSocketService.connectToStomp();
    window.location.href = '/tenants';
  };

  checkAuthentication = () => {
    if (checkAuth() && store.getState().tenant) {
      window.location.href = '/contacts';
      return false;
    } else if (checkAuth()) {
      return true;
    }
    return false;
  };

  render() {
    const { username, password, error, usernameError } = this.state;
    const { t } = this.props;

    if (this.checkAuthentication()) {
      this.goToProfileSelector();
      return null;
    }

    return (
      <div className="login-page">
        <div className="login-container">
          <div className="login-content">
            <img src={logoTunstall} alt="Logo" className="logo" />
            <form className="login-form" onSubmit={this.handleSubmit}>
              <h2 className="login-title"><div dangerouslySetInnerHTML={{ __html: t('LOGIN.LABEL.Title') }} /></h2>
              <p className="login-description">{t('LOGIN.LABEL.SubTitle')}</p>
              <input
                type='text'
                id='username'
                placeholder={t('LOGIN.IMPUT.Email')}
                aria-label={t('LOGIN.IMPUT.Email')}
                value={username}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                className={`input login-input ${usernameError ? 'error' : ''}`}
              />
              {usernameError && <p className="error-message">{usernameError}</p>}
              <input
                type='password'
                id='password'
                placeholder={t('LOGIN.IMPUT.Password')}
                aria-label={t('LOGIN.IMPUT.Password')}
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
                className='input login-input'
              />
              {error && <p className="error-message">{error}</p>}
              <button className={`button login-button`} >{t('LOGIN.BUTTON.InitSession')}</button>
              <a href="/recovery-password" className="forgot-password">{t('LOGIN.LABEL.RecoveryPass')}</a>
            </form>
          </div>
          <footer className="login-footer">
            <section className="help-section">
            <p className="help-text" style={{ color: 'var(--White, #fff)' }}>{t('LOGIN.LABEL.FailSession')}</p>
              <p className="help-link">{t('LOGIN.LABEL.Help')}</p>
            </section>
            <p className="copyright">{t('LOGIN.LABEL.Copyright')}</p>
          </footer>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
