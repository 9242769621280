import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'es-ES',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
    clearLanguage(state) {
      state.language = 'es-ES';
    },
  },
});

export const { setLanguage, clearLanguage } = languageSlice.actions;
export default languageSlice.reducer;
