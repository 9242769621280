import React, {Component} from 'react';
import {JaaSMeeting} from '@jitsi/react-sdk';
import {connect} from 'react-redux';
import ringtone from "../video-call-view/video-call-incoming/assets/audio/ringtone.mp3";
import {playRingtone} from '../../utils/videocall.util.js';

class VideoCallJitsi extends Component {
    state = {
        mainContent: null,
        layout: 'defaultLayout'
    };


    currentUserIsCaller() {
        const {user, videoCall} = this.props;
        return user.userId === videoCall.user.id;
    }

    componentDidMount() {
        playRingtone(this.currentUserIsCaller());
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        console.log("UNMOUNTING VIDEO CALL JITSI COMPONENT");
    }

    handleLayoutChange = (layout) => {
        this.setState({layout});
    }

    handleParticipantJoined(participant) {
        playRingtone(false);
        console.log("VIDEO CALL JITSI COMPONENT Participant joined: ", participant);
    }

    handleVideoConferenceLeft(participant) {
        console.log("VIDEO CALL JITSI COMPONENT Video conference left: ", participant);
        this.props.callClosed();
    }

    handleOnReadyToClose(data) {
        console.log('VIDEO CALL JITSI COMPONENT onReadyToClose:', data);
        this.props.callClosed();
    }

    handleParticipantLeft(participant, externalApi) {
        console.log("VIDEO CALL JITSI COMPONENT Participant left: ", participant);
        if (externalApi.getNumberOfParticipants() === 1) {
            console.log("VIDEO CALL JITSI COMPONENT: Only one participant in the call, closing the call");
            this.props.callClosed();
        }
    }

    render() {
        const {appId, roomId, token, language} = this.props;
        return (<>
                <audio id="ringtone" src={ringtone}></audio>
                <JaaSMeeting
                    appId={appId}
                    roomName={roomId}
                    jwt={token}
                    configOverwrite={{
                        backgroundAlpha: 0.5,
                        disableLocalVideoFlip: true,
                        disableThirdPartyRequests: true,
                        prejoinPageEnabled: false,
                        startWithAudioMuted: false,
                        startWithVideoMuted: false,
                        defaultLanguage: language
                    }}
                    interfaceConfigOverwrite={{
                        VIDEO_LAYOUT_FIT: 'nocrop',
                        MOBILE_APP_PROMO: false,
                        TILE_VIEW_MAX_COLUMNS: 4
                    }}

                    onApiReady={(externalApi) => {
                        externalApi.on('videoConferenceLeft', (participant) => this.handleVideoConferenceLeft(participant));
                        externalApi.on('onReadyToClose', (data) => this.handleOnReadyToClose(data));
                        externalApi.on('participantLeft', (participant) => this.handleParticipantLeft(participant, externalApi));
                        externalApi.on('participantJoined', (participant) => {
                            this.handleParticipantJoined(participant);
                        });
                    }}

                    getIFrameRef={(iframeRef) => {
                        iframeRef.style.height = '100%';
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.language.language,
    user: state.user,
    videoCall: state.videocall.videocallInfo
});
export default connect(mapStateToProps)(VideoCallJitsi);
