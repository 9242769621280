import React from "react";
import Modal from "../modal/modal.component";
import { calculateTimeDifference, getFormattedTimeFromTimestamp } from "../../utils/date.util";
import { Icon } from "@iconify/react";
import { withTranslation } from 'react-i18next';
import styles from './styles/notificationDetail.module.scss';

class NotificationDetail extends React.Component {
  render() {
    const { notification, onClose, t } = this.props;
    const { title, body, date } = notification;

    return (
      <Modal
        title={title}
        titleClassName={styles.modalTitle}
        onClose={onClose}
        image={<Icon icon="mdi:notifications-active" width="40px" height="40px" className={styles.notificationIcon} />}
      >
        <div className={styles.notificationDetailContent}>
          <div className={styles.notificationBody}>{body}</div>
          <div className={styles.notificationTime}>
            <span>{calculateTimeDifference(date, t)}</span>
            <Icon icon="tabler:point-filled" width="10" height="10" />
            <span>{getFormattedTimeFromTimestamp(date)}</span>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(NotificationDetail);
