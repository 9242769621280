import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { clearVideocallInfo } from '../redux/slices/videocallSlice';
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import RecoveryPassword from '../pages/recovery-password';
import ModifyPassword from '../pages/modify-password';
import ProfileView from '../components/profile-view/profileView.component';
import CalendarView from '../components/calendar-view/calendarView.component';
import UsersView from '../components/users-view/usersView.component';
import HomesView from '../components/homes-view/homesView.component';
import ContentView from '../components/content-view/contentView.component';
import EmptyContent from '../components/empty-content/empty-content.component';
import ContactDetail from '../components/contact-detail/contactDetail.component';
import VideoCallJitsi from '../components/video-call-jitsi/videoCallJitsi.component';
import { checkAuth } from '../services/auth/AuthService';
import { changeUserStateToEndCall } from '../services/UserStateService.js';
import TenantProfile from '../components/tenant-profile/tenantProfile.component';
import { hasAdminRole, hasProfesionalRole } from '../utils/credentials.util';
import NotificationAll from '../components/notification-all/notificationAll.component';
import {store} from "../redux/store";
import {notifyVideoCallCancelation} from "../services/VideoCallService";

const ContactsComponent = () => {
    const selectedContact = useSelector(state => state.contact.selectedContact);
    return selectedContact ? <ContactDetail contact={selectedContact} showHistory={true} /> : <EmptyContent view="contact" />;
};

const VideocallsComponent = () => {
    const selectedContact = useSelector(state => state.contact.selectedContact);
    return selectedContact ? <ContactDetail contact={selectedContact} showHistory={true} /> : <EmptyContent view="videocall" />;
};

const ProfileComponent = () => {
    const selectedView = useSelector(state => state.profile.selectedProfileView);
    return <ProfileView selectedComponent={selectedView ? selectedView : 'profile'} />;
};

const VideoCallComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const videocallInfo = useSelector(state => state.videocall.videocallInfo);
    const userId = useSelector(state => state.user.id);
    const callClosed = () => {

        notifyVideoCallCancelation(videocallInfo);
        dispatch(clearVideocallInfo());
        changeUserStateToEndCall(userId);
        navigate(-1);
    };

    if (!videocallInfo) {
        return null;
    }

    return (
        <VideoCallJitsi
            appId={videocallInfo.appId}
            roomId={videocallInfo.roomId}
            token={videocallInfo.token}
            callClosed={callClosed}
        />
    );
};

const Router = () => {
    const isAuthenticated = checkAuth();
    var hasTenant = !!store.getState().tenant.tenant;
    const hasAccessToProtectedRoutes = hasAdminRole() || hasProfesionalRole();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/recovery-password" element={<RecoveryPassword />} />
                <Route path="/modify-password" element={<ModifyPassword />} />
                {isAuthenticated ? (
                    hasTenant ? (
                        <>
                            <Route path="/" element={<Dashboard />}>
                                <Route path="contacts" element={<ContactsComponent />} />
                                <Route path="videocall" element={<VideoCallComponent />} />
                                <Route path="videocalls" element={<VideocallsComponent />} />
                                <Route path="profile" element={<ProfileComponent />} />
                                <Route path="calendar" element={<CalendarView />} />
                                <Route path="notifications" element={<NotificationAll />} />
                                <Route 
                                    path="users" 
                                    element={hasAccessToProtectedRoutes ? <UsersView /> : <Navigate to="/contacts" />} 
                                />
                                <Route 
                                    path="homes" 
                                    element={hasAccessToProtectedRoutes ? <HomesView /> : <Navigate to="/contacts" />} 
                                />
                                <Route 
                                    path="content" 
                                    element={hasAccessToProtectedRoutes ? <ContentView /> : <Navigate to="/contacts" />} 
                                />
                            </Route>
                            <Route path="*" element={<Navigate to="/contacts" />} />
                        </>
                    ) : (
                        <>
                            <Route path="/tenants" element={<TenantProfile />} />
                            <Route path="*" element={<Navigate to="/tenants" />} />
                        </>                        
                    )
                ) : (
                    <Route path="*" element={<Navigate to="/login" />} />
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
