import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userTypes: [],
};

const userTypeSlice = createSlice({
    name: 'userTypes',
    initialState,
    reducers: {
        setUserTypes(state, action) {
            state.userTypes = action.payload.userTypes;
        },
        clearUserTypes(state) {
            state.userTypes = [];
        },
    },
});

export const {
    setUserTypes,
    clearUserTypes,
} = userTypeSlice.actions;
export default userTypeSlice.reducer;