import React, { Component } from "react";
import { Icon } from '@iconify/react';
import Modal from '../modal/modal.component';
import styles from './styles/customModal.module.scss';

class CustomModal extends Component {
  render() {
    const { title, customText, icon, iconClassName, buttons, onClose } = this.props;

    const iconComponent = (
      <div className={styles.iconContainer}>
        <Icon icon={icon} className={`${styles.icon} ${iconClassName}`} />
      </div>
    );

    return (
      <Modal
        title={title}
        titleClassName={styles.title}
        image={iconComponent}
        onClose={onClose}
      >
        <p className={styles.messages}>{customText}</p>
        <div className={styles.buttonGroup}>
          {buttons.map((button, index) => (
            <button
              key={index}
              type="button"
              className={`${styles.actionButton} ${button.className}`}
              onClick={button.onClick}
            >
              {button.label}
            </button>
          ))}
        </div>
      </Modal>
    );
  }
}

export default CustomModal;
