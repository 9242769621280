import React, { Component } from "react";
import CustomInput from "../../custom-input/customInput.component";
import SelectButton from "../select-button/selectButton.component";
import styles from "./styles/notificationCreateModal.module.scss";
import { withTranslation } from "react-i18next";
import Modal from "../../modal/modal.component";

class NotificationCreateModal extends Component {
    validateFields = () => {
        const { title, message } = this.props;
        const errors = {};

        if (!title) errors.title = this.props.t("NOTIFICATIONS.LABEL.RequiredField");
        if (!message) errors.message = this.props.t("NOTIFICATIONS.LABEL.RequiredField");

        this.setState({ validate: true });

        return Object.keys(errors).length === 0;
    };

    handleSubmit = () => {
        if (this.validateFields()) {
            const { title, message } = this.props;
            this.props.onSubmit({ title, message });
        }
    };

    render() {
        const { title, message, validate, t, onClose, onOpenSelectUsers, onOpenSelectHomes, selectedUsers, selectedHomes, onInputChange } = this.props;

        const buttons = [
            {
                label: t("NOTIFICATIONS.LABEL.SendButton"),
                className: styles.confirmButton,
                onClick: this.handleSubmit,
            },
            {
                label: t("NOTIFICATIONS.LABEL.CancelButton"),
                className: styles.cancelButton,
                onClick: onClose,
            },
        ];

        return (
            <Modal
                title={t("NOTIFICATIONS.LABEL.Title")}
                titleClassName={styles.modalTitle}
                buttons={buttons}
            >
                <div className={styles.notificationCreateModal}>
                    <div className={styles.description}>
                        {t("NOTIFICATIONS.LABEL.Description")}
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="title">
                            {t("NOTIFICATIONS.LABEL.TitleQuestion")}
                            <span className={styles.requiredMark}> *</span>
                        </label>
                        <CustomInput
                            type="text"
                            name="title"
                            value={title}
                            onChange={(value) => onInputChange("title", value)}
                            placeholder={t("NOTIFICATIONS.LABEL.TitlePlaceholder")}
                            customClass={styles.inputField}
                            validators={[{ regex: /.+/, message: t("NOTIFICATIONS.LABEL.RequiredField") }]}
                            validate={validate}
                        />

                        <label htmlFor="message">
                            {t("NOTIFICATIONS.LABEL.MessageQuestion")}
                            <span className={styles.requiredMark}> *</span>
                        </label>
                        <CustomInput
                            type="textarea"
                            name="message"
                            value={message}
                            onChange={(value) => onInputChange("message", value)}
                            placeholder={t("NOTIFICATIONS.LABEL.MessagePlaceholder")}
                            customClass={styles.messageField}
                            validators={[{ regex: /.+/, message: t("NOTIFICATIONS.LABEL.RequiredField") }]}
                            validate={validate}
                        />

                        <label htmlFor="users">
                            {t("NOTIFICATIONS.LABEL.UserQuestion")}
                            <span className={styles.requiredMark}> *</span>
                        </label>
                        <div className={styles.buttonGroup}>
                            <SelectButton
                                icon="ph:user-thin"
                                label={t("NOTIFICATIONS.LABEL.SelectUsers")}
                                selectedCount={selectedUsers.length}
                                onClick={onOpenSelectUsers}
                            />
                            <SelectButton
                                icon="mdi:home-outline"
                                label={t("NOTIFICATIONS.LABEL.SelectHomes")}
                                selectedCount={selectedHomes.length}
                                onClick={onOpenSelectHomes}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(NotificationCreateModal);
