import React, { Component } from 'react';

class CanalTVIcon extends Component {
    render() {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='11'
                height='21'
                fill='none'
                viewBox='0 0 11 21'
            >
                <path
                    fill='#FFB800'
                    d='M5.502 5.598c-.337 0-.624-.099-.861-.297-.237-.198-.356-.438-.356-.72 0-.283.118-.524.354-.724.235-.199.522-.298.859-.298.337 0 .624.099.861.296.237.198.356.438.356.721s-.118.524-.354.723c-.235.2-.522.299-.859.299zM5.5 20.5c-1.384 0-2.564-.41-3.538-1.228C.987 18.454.5 17.464.5 16.303V4.697c0-1.161.488-2.151 1.463-2.97C2.938.91 4.117.5 5.5.5s2.562.41 3.538 1.228c.976.818 1.464 1.808 1.462 2.969v11.606c0 1.161-.488 2.151-1.464 2.97C8.06 20.09 6.88 20.5 5.498 20.5M5.5 7.637c1.02 0 1.883-.296 2.588-.887.705-.591 1.057-1.316 1.057-2.173s-.352-1.58-1.057-2.171c-.705-.591-1.568-.886-2.59-.886-1.02 0-1.883.295-2.587.887-.704.591-1.056 1.315-1.056 2.172 0 .857.352 1.581 1.057 2.172.705.59 1.568.886 2.59.886m-1.684 6.667c.25 0 .469-.078.655-.235.187-.156.28-.34.28-.55 0-.21-.093-.394-.28-.55a.985.985 0 00-.656-.234.992.992 0 00-.655.234c-.185.156-.278.34-.28.551 0 .211.093.395.28.55.187.155.406.233.656.234zm0-2.784c.25 0 .469-.079.655-.235.187-.156.28-.34.28-.55 0-.211-.093-.394-.28-.55a.996.996 0 00-.656-.235.977.977 0 00-.655.235c-.185.157-.278.34-.28.55 0 .21.093.394.28.55a.992.992 0 00.656.235zm3.365 0c.251 0 .47-.079.655-.235.185-.156.278-.34.28-.55 0-.211-.093-.394-.28-.55a.999.999 0 00-.656-.235.98.98 0 00-.655.235c-.187.157-.28.34-.28.55 0 .21.093.394.28.55a.99.99 0 00.656.235zm0 2.783c.251 0 .47-.078.655-.235.185-.156.278-.339.28-.549 0-.21-.093-.394-.28-.55a.981.981 0 00-.656-.234c-.25.001-.469.08-.655.234-.187.155-.28.339-.28.551 0 .212.093.395.28.55.186.154.405.231.656.233zm-3.366 2.784a.99.99 0 00.656-.234c.187-.156.28-.34.28-.55 0-.21-.093-.393-.28-.55a.982.982 0 00-.656-.235.99.99 0 00-.655.235c-.185.155-.278.339-.28.55 0 .212.093.395.28.551a1 1 0 00.656.235m3.365 0c.251 0 .47-.079.655-.235.185-.156.278-.34.28-.55 0-.211-.093-.395-.28-.551a.992.992 0 00-.656-.235.987.987 0 00-.655.235c-.187.156-.28.34-.28.55 0 .211.093.395.28.551a.99.99 0 00.656.235z'
                ></path>
            </svg>
        );
    }
}


class AppWebIcon extends Component {
    render() {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='17'
                height='17'
                fill='none'
                viewBox='0 0 17 17'
            >
                <path
                    stroke='#C4C4C4'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                    d='M10.375 3.813H16M13.187 1v5.625M1 1.937A.937.937 0 011.938 1h3.75a.937.937 0 01.937.938v3.75a.937.937 0 01-.938.937h-3.75A.937.937 0 011 5.687v-3.75zm0 9.376a.937.937 0 01.938-.938h3.75a.938.938 0 01.937.938v3.75a.937.937 0 01-.938.937h-3.75A.938.938 0 011 15.062v-3.75zm9.375 0a.938.938 0 01.938-.938h3.75a.938.938 0 01.937.938v3.75a.938.938 0 01-.938.937h-3.75a.938.938 0 01-.937-.938v-3.75z'
                ></path>
            </svg>
        );
    }
}


class AppAndroidIcon extends Component {
    render() {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                fill='none'
                viewBox='0 0 15 15'
            >
                <path
                    fill='#A855F7'
                    d='M2.41 0A2.41 2.41 0 000 2.41v9.108a2.41 2.41 0 002.41 2.41h2.203a2.41 2.41 0 01-.06-.535v-.536H2.412a1.34 1.34 0 01-1.34-1.34V4.287h11.786v.268h.536c.184 0 .363.02.536.059V2.411A2.41 2.41 0 0011.518 0H2.41zM1.072 2.41a1.34 1.34 0 011.34-1.339h9.107a1.34 1.34 0 011.34 1.34v.803H1.07v-.803zM11.25 5.358a1.607 1.607 0 00-1.607 1.607v2.679H6.964a1.607 1.607 0 00-1.607 1.607v2.143A1.607 1.607 0 006.964 15h5.625A2.41 2.41 0 0015 12.59V6.963a1.607 1.607 0 00-1.607-1.607H11.25zm-.536 1.607a.536.536 0 01.536-.535h2.143a.536.536 0 01.536.535v2.679h-3.215V6.964zm-3.75 3.75h2.679v3.215H6.964a.536.536 0 01-.535-.536V11.25a.536.536 0 01.535-.536zm5.625 3.215h-1.875v-3.215h3.215v1.875a1.34 1.34 0 01-1.34 1.34z'
                ></path>
            </svg>
        );
    }
}

class ActividadesGrupoIcon extends Component {
    render() {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='15'
                fill='none'
                viewBox='0 0 20 15'
            >
                <path
                    stroke='#11CC5B'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M10 4.157c.643-2.2 3.07-3.322 8.357-3.343.085 0 .168.014.247.042.078.028.149.069.208.12.06.053.107.114.14.182.032.068.048.14.048.213V11.4c0 .148-.068.29-.188.394a.696.696 0 01-.455.163c-5.143 0-7.13.899-8.357 2.229m0-10.029C9.357 1.957 6.93.835 1.643.814a.727.727 0 00-.247.042.655.655 0 00-.208.12.557.557 0 00-.14.182A.491.491 0 001 1.37v9.962c0 .344.246.624.643.624 5.143 0 7.137.905 8.357 2.229m0-10.029v10.029'
                ></path>
            </svg>
        );
    }
}

class CanalYoutubeIcon extends Component {
    render() {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                fill='none'
                viewBox='0 0 15 15'
            >
                <path
                    fill='#F44336'
                    d='M6.188 0h-.844L4.78 2.156 4.22 0H3.28c.188.563.375 1.031.563 1.594.281.75.469 1.406.469 1.781v2.25h.843v-2.25L6.188 0zm2.25 4.219V2.812c0-.468-.094-.75-.282-1.03-.187-.282-.469-.376-.844-.376s-.656.188-.843.469c-.188.188-.282.469-.282.938v1.5c0 .468.094.75.282.937.187.281.468.375.843.375.375 0 .657-.188.844-.469.188-.093.281-.468.281-.937zm-.75.187c0 .375-.094.563-.375.563-.282 0-.375-.188-.375-.563V2.625c0-.375.093-.563.375-.563.28 0 .375.188.375.563v1.781zm3.562 1.219V1.406h-.75v3.188c-.188.281-.281.375-.469.375a.202.202 0 01-.187-.188V1.406h-.75v3.282c0 .28 0 .468.094.656 0 .187.187.281.468.281s.563-.188.844-.469v.469h.75zm.375 4.219c-.281 0-.375.187-.375.562v.375H12v-.375c0-.375-.094-.562-.375-.562zm-2.719 0c-.094 0-.281.094-.375.187v2.531c.094.094.281.188.375.188.188 0 .281-.188.281-.563v-1.78c0-.376-.093-.563-.28-.563z'
                ></path>
                <path
                    fill='#000'
                    d='M6.188 0h-.844L4.78 2.156 4.22 0H3.28c.188.563.375 1.031.563 1.594.281.75.469 1.406.469 1.781v2.25h.843v-2.25L6.188 0zm2.25 4.219V2.812c0-.468-.094-.75-.282-1.03-.187-.282-.469-.376-.844-.376s-.656.188-.843.469c-.188.188-.282.469-.282.938v1.5c0 .468.094.75.282.937.187.281.468.375.843.375.375 0 .657-.188.844-.469.188-.093.281-.468.281-.937zm-.75.187c0 .375-.094.563-.375.563-.282 0-.375-.188-.375-.563V2.625c0-.375.093-.563.375-.563.28 0 .375.188.375.563v1.781zm3.562 1.219V1.406h-.75v3.188c-.188.281-.281.375-.469.375a.202.202 0 01-.187-.188V1.406h-.75v3.282c0 .28 0 .468.094.656 0 .187.187.281.468.281s.563-.188.844-.469v.469h.75z'
                ></path>
                <path
                    fill='#F44336'
                    d='M13.5 7.781c-.188-.656-.75-1.218-1.313-1.218-1.5-.188-3.093-.188-4.687-.188-1.594 0-3.094 0-4.688.188-.562 0-1.124.562-1.312 1.218-.188.938-.188 1.969-.188 2.907 0 .937 0 1.968.188 2.906.188.656.656 1.125 1.313 1.219C4.405 15 5.905 15 7.5 15c1.594 0 3.094 0 4.688-.188.656-.093 1.218-.562 1.312-1.218.188-.938.188-1.969.188-2.906 0-.938 0-1.97-.188-2.907zm-8.625.844h-.938v4.781h-.843V8.625H2.25v-.844h2.625v.844zm2.25 4.781h-.75v-.469c-.281.376-.563.47-.844.47s-.375-.095-.468-.282c0-.094-.094-.281-.094-.656V9.188h.75v3.28c0 .095.093.188.187.188.188 0 .282-.094.469-.375V9.187h.75v4.22zm2.813-1.312c0 .375 0 .656-.094.844-.094.28-.281.468-.563.468-.281 0-.562-.187-.75-.469v.376h-.75V7.78h.75v1.782c.281-.282.469-.47.75-.47s.469.188.563.47c.094.187.094.468.094.843v1.688zm2.812-.656h-1.5v.75c0 .374.094.562.375.562.188 0 .281-.094.375-.281V12h.75v.563c0 .187-.094.28-.188.468-.187.281-.468.469-.937.469-.375 0-.656-.188-.938-.469-.187-.187-.28-.562-.28-.937v-1.406c0-.47.093-.75.187-.938.187-.281.469-.469.937-.469.375 0 .656.188.844.469.188.188.188.563.188.938v.75h.187z'
                ></path>
            </svg>
        );
    }
}

class LiveYoutubeIcon extends Component {
    render() {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                fill='none'
                viewBox='0 0 15 15'
            >
                <path
                    fill='#00B4E6'
                    fillRule='evenodd'
                    d='M3.784 3.145a47.643 47.643 0 017.432 0l1.66.13a2.02 2.02 0 011.837 1.682c.383 2.292.383 4.63 0 6.92a2.02 2.02 0 01-1.836 1.682l-1.66.13a47.625 47.625 0 01-7.433 0l-1.661-.13a2.02 2.02 0 01-1.836-1.682 20.996 20.996 0 010-6.92 2.02 2.02 0 011.835-1.681l1.662-.13zm2.233 7.104V6.586a.222.222 0 01.336-.19l3.053 1.83a.222.222 0 010 .382l-3.053 1.831a.222.222 0 01-.306-.078.222.222 0 01-.03-.112z'
                    clipRule='evenodd'
                ></path>
            </svg>
        );
    }
}

const categoryMap = {
    'Canal_TV': {
        color: '#FFB800',
        name: 'Canal_TV',
        icon: <CanalTVIcon />
    },
    'APP_Web': {
        color: '#C4C4C4',
        name: 'APP_Web',
        icon: <AppWebIcon />
    },
    'APP_Android': {
        color: '#A855F7',
        name: 'APP_Android',
        icon: <AppAndroidIcon />
    },
    'Actividades_Grupo': {
        color: '#11CC5B',
        name: 'Actividades_Grupo',
        icon: <ActividadesGrupoIcon />
    },
    'Canal_Youtube': {
        color: '#F44336',
        name: 'Canal_Youtube',
        icon: <CanalYoutubeIcon />
    },
    'LIVE_Youtube': {
        color: '#00B4E6',
        name: 'LIVE_Youtube',
        icon: <LiveYoutubeIcon />
    }
};

export function mapCategory(category) {
    return categoryMap[category] || null;
}