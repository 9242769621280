import React, { Component } from "react";
import { Icon } from '@iconify/react';
import styles from './styles/filterBar.module.scss';
import DropdownSelect from "../dropdown-select/dropdownSelect.component";

class FilterBar extends Component {
    handleSearch = (e) => {
        this.props.onSearch(e.target.value);
    };

    handleHomeChange = (selectedOptions) => {
        this.props.onHomeChange(selectedOptions);
    };

    handleUserTypeChange = (selectedOptions) => {
        this.props.onUserTypeChange(selectedOptions);
    };

    clearSearchInput = () => {
        this.props.onSearch('');
    };

    render() {
        const { searchInput, homes, selectedHomes, userTypes, selectedUserTypes, t } = this.props;

        const homeOptions = homes?.map(home => ({ key: home.id, value: home.name }));
        const userTypeOptions = userTypes.map(userType => ({ key: userType.id, value: t("USER_TYPE."+userType.name) }));

        return (
            <>
                <div className={styles.search}>
                    <div className={styles.searchInputWrapper}>
                        <Icon icon="iconamoon:search" className={styles.searchIcon} />
                        <input
                            type="text"
                            id="search-input"
                            placeholder={t("FILTERBAR.LABEL.SearchBar")}
                            className={styles.searchInput}
                            value={searchInput}
                            onChange={this.handleSearch}
                        />
                        <div onClick={this.clearSearchInput}>
                            <Icon icon="ic:outline-clear" className={styles.clearSearchIcon} />
                        </div>
                    </div>
                </div>
                <div className={styles.selects}>
                    <DropdownSelect
                        options={userTypeOptions}
                        selectedOptions={userTypeOptions?.filter(option => selectedUserTypes.includes(option.key))}
                        setSelectedOptions={this.handleUserTypeChange}
                        placeholder={t("FILTERBAR.LABEL.SelectUserType")}
                        isMultiple={true}
                        searchable={false}
                        customClass={styles.select}
                        noOptionsText={t('USER_TYPE.Undefined')}
                    />
                    <DropdownSelect
                        options={homeOptions}
                        selectedOptions={homeOptions?.filter(option => selectedHomes.includes(option.key))}
                        setSelectedOptions={this.handleHomeChange}
                        placeholder={t("FILTERBAR.LABEL.SelectHome")}
                        isMultiple={true}
                        searchable={false}
                        customClass={styles.select}
                        noOptionsText={t('HOMES.LABEL.NoHome')}
                    />
                </div>
            </>
        );
    }
}

export default FilterBar;
