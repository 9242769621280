import React, {Component} from "react";
import {Icon} from '@iconify/react';
import styles from './assets/styles/incomingVideoCall.module.scss';
import ringtone from '../video-call-incoming/assets/audio/ringtone.mp3';
import {playRingtone} from '../../../utils/videocall.util.js';
import {withTranslation} from 'react-i18next';

import { getImage } from '../../../services/BlobService';
import Avatar from "../../avatar/avatar.component";


class ActionButton extends Component {
    render() {
        const {label, className, title, icon, iconClassName, onClick} = this.props;
        return (
            <button
                type="button"
                className={`${styles.actionButton} ${className}`}
                title={title}
                onClick={onClick}
            >
                <Icon icon={icon} className={iconClassName}/>
                {label}
            </button>
        );
    }
}


class IncomingVideoCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null
        };
    }

    fetchImage = async (imageId) => {
        if (imageId) {
            this.setState({isLoadingImage: true});
            try {
                const url = await getImage(imageId);
                this.setState({imageSrc: url, image: url, isLoadingImage: false});
            } catch (error) {
                console.error('Error fetching image:', error);
                this.setState({isLoadingImage: false});
            }
        } else {
            this.setState({isLoadingImage: false});
        }
    };

    componentDidMount() {
        const {sender} = this.props;
        playRingtone(true);
        this.fetchImage(sender.avatar);
    }

    handleOverlayClick = (e) => {
        const {onClose} = this.props;
        if (onClose && e.target === e.currentTarget) {
            onClose();
        }
    };

    render() {
        const { onClose,onAccept, sender} = this.props;
        const {t} = this.props;
        const {imageSrc} = this.state;
        return (
            <div className={styles.modalOverlay} onClick={this.handleOverlayClick}>
                <audio id="ringtone" src={ringtone}></audio>
                <div className={styles.modal}>
                    {onClose && (
                        <button className={styles.closeButton} onClick={onClose}>
                            <Icon icon="mdi:close"/>
                        </button>
                    )}
                    <div className={styles.contact}>
                        {imageSrc ? (
                            <div className={styles.avatar}>
                                <Avatar image={imageSrc}
                                        name={sender.name}
                                        size="avatar-hard"
                                        status={sender.status}
                                />
                            </div>
                        ) : (
                            imageSrc && (
                                typeof imageSrc === 'string' ? (
                                    <img src={imageSrc} alt="modal" className={styles.modalImage}/>
                                ) : (
                                    <div className={styles.iconContainer}>
                                        {imageSrc}
                                    </div>
                                )
                            )
                        )}
                        <div className={styles.contactRole}>
                            <span className={styles.contactRoleText}>{sender.role ? sender.role.charAt(0).toUpperCase() + sender.role.slice(1) : ''}</span>
                        </div>
                        <div className={styles.contactName}>
                            <span className={styles.contactNameText}>{sender.name}</span>
                        </div>
                        <div className={styles.message}>
                            <span className={styles.messageText}>{t('VIDEOCALL.INCOMING.Message')}</span>
                        </div>
                    </div>
                    <div className={styles.buttonGroup}>
                        <ActionButton
                            iconClassName={styles.acceptButtonClassName}
                            icon={'line-md:phone-call-loop'}
                            onClick={onAccept}
                        />
                        <ActionButton
                            icon={'fluent:call-end-32-regular'}
                            iconClassName={styles.rejectButtonClassName}
                            onClick={onClose}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(IncomingVideoCall);
