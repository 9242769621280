import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  rows: [],  // Lista de filas, cada una con una propiedad `active`
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    toggleActiveStatus: (state, action) => {
      const { rowId, active } = action.payload;
      const row = state.rows.find(row => row.id === rowId);
      if (row) {
        row.active = active;  // Actualiza la propiedad `active` de la fila
      }
    },
  },
});

export const { setRows, toggleActiveStatus } = contentSlice.actions;
export default contentSlice.reducer;
